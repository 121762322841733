import React from 'react'
import { ReactComponent as CaretRightIcon } from '__shared/images/icons/caret.svg'

const WeeklyFilter = ({ previousWeek, nextWeek, headers }) => {
    const generateWeekRange = () => {
        const from = headers[0]?.split(' ')[1]
        const to = headers[6]?.split(' ')[1]
        return `${from} - ${to}`
    }
    return (
        <div>
            <div className="flex items-center py-1 text-center bg-gray-300 rounded-lg">
                <button
                    className="flex items-center justify-center w-6 h-6 p-1 disabled:opacity-20"
                    onClick={previousWeek}
                >
                    &lt;
                </button>
                <h3 className="flex-auto font-bold text-[1.5rem]">
                    {generateWeekRange()}
                </h3>
                <button
                    className="flex items-center justify-center w-6 h-6 p-1 disabled:opacity-20"
                    onClick={nextWeek}
                >
                    &gt;
                </button>
            </div>
        </div>
    )
}

export default WeeklyFilter
