import React from 'react'
import { Link } from 'react-router-dom'

const FooterLinks = ({ franchises }) => {
    return (
        <section className="bg-wbslate">
            <div className="max-w-6xl px-6 pt-6 pb-8 mx-auto lg:px-8">
                <div className="xl:grid xl:grid-cols-2 xl:gap-8">
                    <div className="grid grid-cols-2 gap-8 mt-16 xl:col-span-2 xl:mt-0 place-items-center md:place-items-stretch">
                        <div className="md:grid md:grid-cols-2 md:gap-8 font-openSans ">
                            <div className=" md:border-r">
                                <h3 className="text-lg font-bold text-white font-openSans">
                                    Explore Our Brands
                                </h3>
                                <ul role="list" className="mt-6 space-y-1">
                                    {franchises?.map((franchise) => (
                                        <li
                                            key={franchise.id}
                                            className="leading-4"
                                        >
                                            <Link
                                                to={franchise.slug}
                                                className="text-sm text-blue hover:text-white"
                                            >
                                                {franchise.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:border-r md:mt-0">
                                <h3 className="text-lg font-bold text-white font-openSans">
                                    News
                                </h3>
                                <ul role="list" className="mt-6 space-y-1 ">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8 font-openSans">
                            <div className="md:border-r">
                                <h3 className="text-lg font-bold text-white font-openSans">
                                    Resources
                                </h3>
                                <ul role="list" className="mt-6 space-y-1">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0 ">
                                <h3 className="text-lg font-bold text-white font-openSans">
                                    Favorites
                                </h3>
                                <ul role="list" className="mt-6 space-y-1 ">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm leading-4 text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-sm text-blue hover:text-white"
                                        >
                                            Placeholder Link
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FooterLinks
