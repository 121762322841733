import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from 'utils/utils'
import WbLogo from '__shared/images/icons/wb-logo.png'
import './ProfileDropdown.scss'
import { useAtomValue } from 'jotai'
import { userInfoAtom } from 'utils/atoms'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
const userNavigation = [
    // { name: 'Products', href: '#' },
    // { name: 'Financials', href: '#' },
    // { name: 'Business plans', href: '#' },
    // { name: 'Resources', href: '#' },
    // { name: 'Favorites', href: '#' },
    { name: 'Support', link: '/support' },
]

const ProfileDropdown = () => {
    const userInfo = useAtomValue(userInfoAtom)
    const [isProfileOpen, setIsProfileOpen] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['authToken'])
    const navigate = useNavigate()

    return (
        <Menu as="div" className="relative profile-dropdown">
            <div className="franchiseNav-buttons">
                <Menu.Button
                    title="User Profile"
                    className="flex items-center justify-center text-white rounded-full profile-icon focus:ring-1 group focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 "
                >
                    <span className="sr-only">Open user menu</span>
                    <FontAwesomeIcon
                        icon={faUserCircle}
                        size="2xl"
                    ></FontAwesomeIcon>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 py-1 mt-2 font-extrabold tracking-widest uppercase origin-top-right bg-white rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none ">
                    <div className="px-4 py-2 ">
                        <div className="grid items-center grid-cols-3 pb-3 border-b border-black gap-x-2">
                            <img
                                src={WbLogo}
                                className="object-contain w-full h-24 wb-logo invert"
                            />
                            <div className="col-span-2 text-center ">
                                <h2 className="my-0 overflow-hidden text-2xl font-bold leading-7 tracking-wider ">
                                    {userInfo?.display_name}
                                </h2>
                                <p className=" font-bold text-gray-700 uppercase text-[10px] text-ellipsis font-wbSans tracking-[5%] overflow-hidden">
                                    {userInfo?.user_email}
                                </p>
                            </div>
                        </div>
                    </div>
                    {userNavigation.map(({ name, link }) => (
                        <Menu.Item
                            key={name}
                            className="py-0 text-lightroyalblue "
                        >
                            {({ active }) => (
                                <Link
                                    to={link}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        name === 'My account' ? 'py-2' : '',
                                        'block px-4 text-sm text-gray-700 font-extrabold font-openSans'
                                    )}
                                >
                                    {name}
                                </Link>
                            )}
                        </Menu.Item>
                    ))}
                    <Menu.Item className="py-0">
                        {({ active }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    removeCookie('authToken', {
                                        path: '/',
                                        maxAge: 36000,
                                        sameSite: 'none',
                                        secure: true,
                                        ...(window.location.hostname !==
                                            'localhost' && {
                                            domain: 'warnerbros.com',
                                        }),
                                    })
                                    navigate('/')
                                }}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'font-openSans block px-4 py-2 text-sm text-lightroyalblue ml-auto uppercase tracking-wider'
                                )}
                            >
                                Log out
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ProfileDropdown
