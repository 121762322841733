import TopNav from './components/topnav'
import Calendar from './components/calendar'
import './calendar.scss'
import Popup from './components/eventPopup'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import useApi from 'hooks/useApi'
import { useParams, Link } from 'react-router-dom'
import { objectToQueryParams } from '../../utils/utils'
import brandBgImg from '__shared/images/brand-hero-bg.png'
import FranchiseTopbar from 'pages/franchise/components/FranchiseTopbar/FranchiseTopbar'
import Footer from 'components/Footer/Footer'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'
import AgendaView from './components/AgendaView/AgendaView'
import { useAtomValue } from 'jotai'
import { userInfoAtom } from 'utils/atoms'
import useLocalStorage from 'hooks/useLocalStorage'
import { useCookies } from 'react-cookie'
import Loading from 'components/Loading/Loading'

export default function BigCalendar() {
    const params = useParams()
    const { currentFranchise, currentBrand } = useGetCurrentLevelNav()
    const [isEventsLoading, setIsEventsLoading] = useState(true)

    const userInfo = useAtomValue(userInfoAtom)
    const [cookies] = useCookies(['authToken'])
    const authToken = cookies.authToken

    const [selectedCategories, setSelectedCategories] = useLocalStorage(
        'categoriesSelection',
        [{ value: 'Public', label: 'Public' }]
    )

    const [events, setEvents] = useState(null)
    const [currentDate, setCurrentDate] = useState(() => new Date())
    // const [agendaViewEvents, setAgendaViewEvents] = useState([])
    // const [agendaViewHeaders, setAgendaViewHeaders] = useState([])
    // const [sortedContentTags, setSortedContentTags] = useState([])
    const [sortedCategoryTags, setSortedCategoryTags] = useState([])
    const [eventsCountByYearView, setEventsCountByYearView] = useState('')
    const currentYear = new Date().getFullYear()

    const [years, setYears] = useState([])
    const months = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
    ]
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const [headers, setHeaders] = useState([])
    const [filter, setFilter] = useState({
        type: 'year',
        start: new Date().getFullYear().toString(),
        end: new Date().getFullYear().toString(),
    })
    const [view, setView] = useState('agenda')
    // const [menuActive, setMenuActive] = useState(false)
    const [data, setData] = useState([])
    // const [activeFranchise, setActiveFranchise] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState(null)

    const { responseJSON: themes } = useApi(`themes`, 'get')

    const { responseJSON: eventCategories } = useApi(
        `setting/allowed-event-categories`,
        'get'
    )

    const userAllowedEventCategories = eventCategories?.filter(
        (eventCategory) => userInfo?.allowed_themes?.includes(eventCategory)
    )

    useEffect(() => {
        let shouldCancel = false

        setIsEventsLoading(true)
        const defaultHeaders = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        }
        const paramsCopy = { ...params }
        paramsCopy.franchise = currentFranchise?.id
        paramsCopy.brand = params?.brand

        paramsCopy.categories =
            selectedCategories.length < 1
                ? null
                : selectedCategories.map((c) => c.value).join(',')

        const getEvents = async () => {
            try {
                const response = await fetch(
                    `${
                        process.env.REACT_APP_FRANCHISE_PROXY_URL
                    }/events?${objectToQueryParams(paramsCopy)}`,
                    {
                        credentials: 'include',
                        headers: {
                            ...defaultHeaders,
                        },
                    }
                )
                const eventsData = await response.json()

                if (response.status === 500) {
                    setEvents([])
                } else {
                    const eventsDataWithIsVisibleProperty = eventsData.map(
                        (event) => ({
                            ...event,
                            isVisible: true,
                        })
                    )
                    setEvents(eventsDataWithIsVisibleProperty)
                }
            } catch (e) {
                console.log(e)
            }
            setIsEventsLoading(false)
        }

        getEvents()
        return () => (shouldCancel = true)
    }, [selectedCategories, currentFranchise])

    const previousWeek = useCallback(() => {
        const previousDate = new Date(currentDate)
        previousDate.setDate(currentDate.getDate() - 7)
        setCurrentDate(previousDate)
    }, [currentDate])

    const nextWeek = useCallback(() => {
        const nextDate = new Date(currentDate)
        nextDate.setDate(currentDate.getDate() + 7)
        setCurrentDate(nextDate)
    }, [currentDate])

    const generateYears = async () => {
        if (view === 'agenda' || view === 'week') return
        return await new Promise((resolve, reject) => {
            const years = []
            const year = new Date().getFullYear()
            for (let i = 0; i <= 10; i++) {
                years.push((year + i).toString())
            }
            setYears(years)
            resolve()
        })
    }

    const generateHeaders = async () => {
        return await new Promise((resolve, reject) => {
            let headers = []
            if (view === 'year' || view === 'agenda') {
                headers = months
            }
            if (view === 'multi-year') {
                headers = years
            }
            if (view === 'week') {
                // loop to create 7 days starting with the Sunday of the current week, based on current day.
                for (let i = 0; i < 7; i++) {
                    const currentDateClone = new Date(currentDate)
                    currentDateClone.setDate(
                        currentDate.getDate() - currentDate.getDay() + i
                    )
                    const date = currentDateClone.getDate()

                    const month = currentDateClone.getMonth() + 1

                    const dayOfWeek = daysOfWeek[i]

                    headers.push(`${dayOfWeek} ${month}/${date}`)
                }
            }

            setHeaders(headers)
            resolve()
        })
    }

    useEffect(() => {
        if (view === 'agenda') return
        const prepare = async () => {
            await generateYears()
            await generateHeaders()
        }

        prepare().catch(console.error)
    }, [view, currentDate])

    const renderEvents = () => {
        if (isEventsLoading)
            return (
                <div className="w-full pb-10 overflow-x-auto bg-white border-collapse min-h-[400px] rounded-br-xl rounded-bl-xl">
                    <div className="mx-auto px-18 max-w-8xl">
                        <Loading />
                    </div>
                </div>
            )
        if (view === 'agenda') {
            return (
                <AgendaView
                    events={events}
                    currentYear={currentYear}
                    setSortedCategoryTags={setSortedCategoryTags}
                    setEventsCountByYearView={setEventsCountByYearView}
                />
            )
        }
        if (!events) return null
        const indexer = view === 'multi-year' ? 0 : 1

        let eventsCount = 0

        let eventsFilteredByAllowedCategories

        userAllowedEventCategories?.forEach((eventCategory) => {
            eventsFilteredByAllowedCategories = events.reduce(
                (filtered, event) => {
                    if (
                        event.metadata?.event_category?.indexOf(eventCategory) >
                        -1
                    ) {
                        const start = event.metadata.start_date.split('-')

                        const end = event.metadata.end_date.split('-')

                        const isMultiYear = end[0] > start[0]
                        const isSameDay =
                            event.metadata.start_date == event.metadata.end_date
                        const isAnnounced =
                            event.metadata.announced === '1' ? true : false
                        if (
                            (view === 'multi-year' &&
                                filter.start <= start[0] &&
                                filter.end >= start[0]) ||
                            (filter.start > start[0] &&
                                filter.start <= end[0] &&
                                view === 'multi-year')
                        ) {
                            eventsCount += 1
                            filtered.push({
                                start_index: headers.indexOf(
                                    Math.max(
                                        start[indexer],
                                        filter.start
                                    ).toString()
                                ),
                                end_index: headers.indexOf(
                                    Math.min(
                                        end[indexer],
                                        filter.end
                                    ).toString()
                                ),
                                isMultiYear,
                                isSameDay,
                                isAnnounced,
                                ...event,
                            })
                        } else if (view === 'week') {
                            const eventStartDate = new Date(
                                event.metadata.start_date.replaceAll('-', '/')
                            )

                            const eventEndDate = new Date(
                                event.metadata.end_date.replaceAll('-', '/')
                            )
                            // Create two clones, for beginning/end of week
                            const currentDateClone = new Date(currentDate)
                            const currentDateClone2 = new Date(currentDate)

                            // Get the Sunday of current week
                            currentDateClone.setDate(
                                currentDate.getDate() - currentDate.getDay()
                            )
                            // Set the hours to zero to compare days as a whole and avoid time discrepancies
                            currentDateClone.setHours(0, 0, 0, 0)

                            // Get Saturday(last day of week).
                            currentDateClone2.setDate(
                                currentDate.getDate() - currentDate.getDay() + 6
                            )

                            currentDateClone2.setHours(0, 0, 0, 0)
                            const firstDateOfWeek = currentDateClone
                            const lastDateOfWeek = currentDateClone2

                            const isMultiCalendarWeek =
                                getWeekNumber(eventStartDate) !=
                                    getWeekNumber(eventEndDate) || isMultiYear

                            if (
                                eventStartDate <= lastDateOfWeek &&
                                eventEndDate >= firstDateOfWeek
                            ) {
                                eventsCount += 1
                                const eventRowIndexes = []
                                for (let i = 0; i < 7; i++) {
                                    const currentDateClone = new Date(
                                        currentDate
                                    )
                                    currentDateClone.setHours(0, 0, 0, 0)
                                    currentDateClone.setDate(
                                        currentDate.getDate() -
                                            currentDate.getDay() +
                                            i
                                    )

                                    if (
                                        currentDateClone >= eventStartDate &&
                                        currentDateClone <= eventEndDate
                                    ) {
                                        eventRowIndexes.push(i)
                                    }
                                }
                                // Uses the first and last index to determine event span on table
                                filtered.push({
                                    start_index: eventRowIndexes[0],
                                    end_index:
                                        eventRowIndexes[
                                            eventRowIndexes.length - 1
                                        ],
                                    isMultiYear,
                                    isSameDay,
                                    isAnnounced,
                                    isMultiCalendarWeek,
                                    ...event,
                                })
                            }
                        } else if (
                            !filter.start ||
                            filter.start === start[0] ||
                            (filter.start > start[0] && filter.start <= end[0])
                        ) {
                            eventsCount += 1
                            // event grouped by theme, now it will be filtered by start date
                            filtered.push({
                                start_index:
                                    start[0] < filter.start
                                        ? 0
                                        : headers.indexOf(start[indexer]),
                                end_index:
                                    filter.start < end[0]
                                        ? 11
                                        : headers.indexOf(end[indexer]),
                                isMultiYear,
                                isSameDay,
                                isAnnounced,
                                ...event,
                            })
                        }
                    }
                    return filtered
                },
                []
            )
            console.log(eventsFilteredByAllowedCategories)
        })

        const eventsVisible = eventsFilteredByAllowedCategories?.filter(
            (event) => event.isVisible
        )

        const categoryTypes = {}

        // {Public: [{id, author, title, etc}]}

        const fCategories = {}

        // {Public: {'HBo': [event],'Tv/other': [event] }}

        eventsVisible?.forEach((event) => {
            const event_category = event.metadata.event_category
            const fCategory = event.metadata.fcategory

            if (!categoryTypes[event_category]) {
                categoryTypes[event_category] = []
            }
            if (!fCategories[event_category]) {
                fCategories[event_category] = {}
            }
            if (event.metadata.fcategory) {
                if (!fCategory[event_category]?.[fCategory]) {
                    fCategories[event_category][fCategory] = []
                }
                fCategories[event_category][fCategory].push(event)
            } else {
                categoryTypes[event_category].push(event)
            }
        })

        const sortedCategoryTypes = Object.keys(categoryTypes).sort()

        // ['Public']
        const sortedFCategories = {}

        for (const event_category in fCategories) {
            sortedFCategories[event_category] = Object.keys(
                fCategories[event_category]
            ).sort()
        }
        // {Public: ['hbo', 'tv/other', 'home entertainment']}

        if (events == null) return null

        return (
            <>
                <Calendar
                    themes={themes}
                    headers={headers}
                    setHeaders={setHeaders}
                    events={events}
                    data={data}
                    years={years}
                    view={view}
                    eventCategories={eventCategories}
                    userAllowedEventCategories={userAllowedEventCategories}
                    // toggleEventPopup={toggleEventPopup}
                    filter={filter}
                    setFilter={setFilter}
                    isEventsLoading={isEventsLoading}
                    previousWeek={previousWeek}
                    nextWeek={nextWeek}
                    sortedCategoryTypes={sortedCategoryTypes}
                    sortedFCategories={sortedFCategories}
                    categoryTypes={categoryTypes}
                    fCategories={fCategories}
                    setSelectedEvent={setSelectedEvent}
                />
                {renderModal()}
            </>
        )
    }
    // const changeActiveFranchise = (selectedFranchise) => {
    //     const activeFranchise = franchises.find(
    //         ({ post_name }) => post_name === selectedFranchise
    //     )
    //     setActiveFranchise(activeFranchise)
    //     setMenuActive(selectedFranchise ? false : true)
    // }

    const renderModal = () => {
        if (!selectedEvent) {
            return null
        }
        return (
            <Popup
                selectedEvent={selectedEvent}
                open={true}
                onClose={() => setSelectedEvent(null)}
                events={events}
                setEvents={setEvents}
            />
        )
    }

    return (
        <main className="bg-wbslate article-page ">
            <div className="min-h-franchise-article pb-20">
                <FranchiseTopbar calendar={true} />

                <section className="relative pb-4 bg-gray-900 overflow-hidden isolate pt-24 max-w-[1440px] mx-auto">
                    <img
                        src={brandBgImg}
                        alt="backgroundWBWaterTowerImage"
                        className="absolute inset-0 object-cover w-full -z-10 "
                    />
                    <div
                        className="absolute inset-x-0 top-0 bottom-0 overflow-hidden -z-10 transform-gpu blur-3xl"
                        aria-hidden="true"
                    >
                        <div className="relative aspect-square bg-gradient-to-b from-[#000519] to-[#000519] opacity-60" />
                    </div>

                    <main className="relative pb-0 mx-auto mt-12 md:pb-24 lg:pb-0 ">
                        <div className="flex flex-col items-center mx-auto gap-y-8 max-w-8xl ">
                            <div className="w-full max-w-2xl mx-auto text-center lg:max-w-none lg:flex-auto ">
                                <h1 className="text-7xl font-extrabold text-white font-openSans tracking-wider">
                                    <span className="drop-shadow-xl">
                                        {currentFranchise?.title ||
                                            currentBrand?.title ||
                                            'Calendar'}{' '}
                                        Hub
                                    </span>
                                </h1>
                                {(params.franchise || params.brand) && (
                                    <h2 className="text-xl font-bold tracking-[.5em] text-white mt-7 font-openSans">
                                        Earth-2's Mightiest Heroes
                                    </h2>
                                )}
                                {/* <div className="max-w-md py-4 mx-auto my-12 bg-black sm:max-w-3xl lg:px-2 lg:max-w-4xl">
                                    <ul className="flex flex-col flex-wrap items-center justify-center divide-y-4 lg:divide-y-0 lg:divide-x-2 lg:flex-row">
                                        {navLinks.map(({ name, link }) => (
                                            <li
                                                key={name}
                                                className="w-1/2 py-4 lg:w-fit"
                                            >
                                                <Link
                                                    to={link}
                                                    className="px-5 py-4 text-2xl font-bold uppercase lg:text-3xl hover:opacity-80"
                                                >
                                                    {name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="relative mx-4 overflow-x-auto pt-14 bg-red">
                            <TopNav
                                // capture={this.capture}
                                // favorites={favorites}
                                // favorite={this.favorite}
                                events={events}
                                eventsCountByYearView={eventsCountByYearView}
                                sortedCategoryTags={sortedCategoryTags}
                                view={view}
                                setView={(view) => setView(view)}
                                // activeFranchise={activeFranchise}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                userAllowedEventCategories={
                                    userAllowedEventCategories
                                }
                            />
                            {renderEvents()}
                        </div>
                    </main>
                </section>
            </div>
            <Footer
                className="h-20 text-white bg-wbslate absolute bottom-0 left-0 right-0"
                calendar
            />
        </main>
    )
}
function getWeekNumber(date) {
    const startOfYear = new Date(date.getFullYear(), 0, 1)
    const timeDiff = date.getTime() - startOfYear.getTime()
    const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24))
    const weekNumber = Math.ceil((dayDiff + startOfYear.getDay() + 1) / 7)
    return weekNumber
}
