import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'
import { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/20/solid'
import Loading from 'components/Loading/Loading'
import { useCookies } from 'react-cookie'

export default function Login() {
    const usernameRef = useRef()
    const errorRef = useRef()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [cookies, setCookie, removeCookie] = useCookies(['authToken'])
    const authToken = cookies.authToken

    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        usernameRef?.current?.focus()
    }, [])

    useEffect(() => {
        setErrorMsg('')
    }, [username, password])

    const loginAttempt = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_FRANCHISE_PROXY_URL}/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username, password }),
                    credentials: 'include',
                }
            )

            if (!response.ok) {
                throw new Error(response.statusText)
            }
            let data = await response.json()

            if (data) {
                setCookie('authToken', data.token, {
                    path: '/',
                    maxAge: 36000,
                    sameSite: 'none',
                    secure: true,
                    ...(window.location.hostname !== 'localhost' && {
                        domain: 'warnerbros.com',
                    }),
                })
                setUsername('')
                setPassword('')
                // get return url from location state
                const { from } = location.state || {
                    from: { pathname: '/franchise' },
                }
                navigate(from, { replace: true })
            }
        } catch (error) {
            if (error.message === 'Failed to fetch') {
                setErrorMsg('No server response')
            } else {
                console.log(error)
                setErrorMsg('Login Failed')
            }
            errorRef.current.focus()
        }
        setIsLoading(false)
    }
    // prevent showing login screen when already authenticated
    if (authToken) {
        return <Navigate to="/franchise" />
    }
    return (
        <div className="w-full mx-auto login standard-page max-w-8xl">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>
            <main className="flex flex-col items-center w-64 mx-auto text-black min-h-page">
                <h3 className="text-4xl text-white my-9">Login</h3>
                <form onSubmit={loginAttempt} className="w-full">
                    <div>
                        <input
                            ref={usernameRef}
                            type="email"
                            name="email"
                            className="px-1 py-1 mb-3 text-sm rounded-sm font-arial w-100"
                            onChange={(e) => setUsername(e.currentTarget.value)}
                            value={username}
                            aria-label="username"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            name="password"
                            className="px-1 py-1 mb-3 text-sm rounded-sm font-arial w-100"
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            value={password}
                            placeholder="Password"
                            aria-label="password"
                            required
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <button
                            disabled={isLoading}
                            className="flex justify-center items-center w-20 h-8 px-4.5 py-1 mb-3 font-bold text-white rounded-md shadow-sm font-wbSans bg-midblue hover:bg-midbluehover focus:outline-none focus:ring-2 focus:ring-midblue focus:ring-offset-2 ease-linear duration-150 uppercase"
                        >
                            {isLoading ? <Loading type="13" /> : 'Login'}
                        </button>
                        <div
                            ref={errorRef}
                            className={`${
                                !errorMsg && 'absolute left-[9999px]'
                            } p-1 rounded-md bg-red-50 `}
                        >
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon
                                        className="w-5 h-5 text-red-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">
                                        {errorMsg}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    )
}
