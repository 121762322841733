import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import WbLogo from '__shared/images/icons/wb-logo.png'
import { Popover } from '@headlessui/react'
import { useAtom } from 'jotai'
import { loadableNavAtom } from 'utils/atoms'
import './NavPopover.scss'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'

const resources = {
    name: 'Resources',
    links: [
        { name: 'Favorites', link: '#' },
        {
            name: 'Star Labs',
            link: 'https://portal.starlabs.warnerbros.com/#!/home',
        },
    ],
}

const NavPopover = ({ handlePopoverClose, isPopoverOpen }) => {
    const { brand, franchise, property } = useParams()
    const [{ data: brands }] = useAtom(loadableNavAtom)
    const [guidelineCategories, setGuidelineCategories] = useState([])

    const { currentBrand, currentFranchise, currentSelection } =
        useGetCurrentLevelNav()

    const getFranchisePropertyLinks = () => {
        if (property) return null
        let selection
        if (!brand) {
            selection = brands
        } else {
            selection = currentSelection?.children
        }
        const filterType = franchise
            ? 'property'
            : brand
            ? 'franchise'
            : 'brand'
        return selection
            ?.filter((c) => c.type === filterType)
            .map(({ slug, title, id }) => (
                <Popover.Button
                    as={Link}
                    key={id}
                    to={slug}
                    onClick={() => {
                        handlePopoverClose()
                    }}
                    className="block w-full mt-1 mb-1 text-xs font-semibold text-black border-b border-gray-400 hover:opacity-60"
                >
                    {title}
                </Popover.Button>
            ))
    }

    const products = [
        {
            name: 'Overview',
            links: [
                // {
                //     name: 'Calendar',
                //     link: franchise
                //         ? `${brand}/${franchise}/calendar`
                //         : brand
                //         ? `${brand}/calendar`
                //         : '/tools/calendar',
                // },
            ],
        },
    ]
    const getLinks = () => {
        if (currentFranchise) {
            return guidelineCategories.map((guideline) => (
                <li key={guideline}>
                    <Popover.Button
                        as={Link}
                        to={`/franchise/${brand}/${franchise}/guideline`}
                        onClick={() => {
                            handlePopoverClose()
                        }}
                        className="block w-full mt-1 mb-1 text-xs font-semibold text-black border-b border-gray-400 hover:opacity-60"
                    >
                        {guideline}
                    </Popover.Button>
                </li>
            ))
        }
        return resources.links.map(({ name, link }) => (
            <li key={name}>
                <Popover.Button
                    as={Link}
                    to={link}
                    onClick={() => {
                        handlePopoverClose()
                    }}
                    className="block w-full mt-1 mb-1 text-xs font-semibold text-black border-b border-gray-400 hover:opacity-60"
                >
                    {name}
                </Popover.Button>
            </li>
        ))
    }

    // useEffect(() => {
    //     if (!currentFranchiseArticles) return
    //     setGuidelineCategories(
    //         Object.keys(currentFranchiseArticles).filter((category) =>
    //             category.toLowerCase().endsWith('guidelines')
    //         )
    //     )
    // }, [currentFranchiseArticles])

    return (
        <Popover.Group className="items-center hidden sm:flex sm:gap-x-12 navpopover">
            <Popover>
                {({ open }) => (
                    <>
                        {/* <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 -translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-50"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-1"
                            // onMouseEnter={handlePopoverOpen}
                            // onMouseLeave={handlePopoverClose}
                            // static
                        > */}
                        <Popover.Panel
                            static
                            onMouseLeave={handlePopoverClose}
                            className={`${
                                isPopoverOpen
                                    ? 'show-submenu translate-y-0'
                                    : 'submenu -translate-y-full'
                            } absolute inset-x-0 top-[97px] -z-10 px-4 bg-white md:px-10 pb-2.5`}
                        >
                            <div className="flex mx-auto max-w-8xl gap-x-4 mt-0.5 max-h-[700]">
                                <article className="w-full max-w-xs px-8 mb-8 border-r border-gray-300 ">
                                    <Popover.Button
                                        as={Link}
                                        to="/franchise"
                                        onClick={() => {
                                            handlePopoverClose()
                                        }}
                                    >
                                        <img
                                            src={WbLogo}
                                            alt="Wb-logo"
                                            className="object-contain mx-auto h-28 invert"
                                        ></img>
                                    </Popover.Button>
                                    <h3 className="py-4 mb-4 text-[26px] font-semibold leading-6 tracking-widest text-center uppercase font-openSans border-b border-gray-300">
                                        {currentSelection
                                            ? currentSelection.title
                                            : 'Franchise'}{' '}
                                        Hub
                                    </h3>
                                    <h4 className="pb-4 text-sm font-semibold tracking-wide text-center text-gray-500 uppercase font-openSans">
                                        Explore our brands
                                    </h4>
                                    <div className="grid items-center justify-center grid-cols-3 gap-2 mb-4">
                                        {brands?.map(
                                            ({
                                                slug,
                                                title,
                                                explore_icon,
                                                id,
                                            }) => {
                                                return (
                                                    <Popover.Button
                                                        as={Link}
                                                        to={slug}
                                                        key={id}
                                                        onClick={() => {
                                                            handlePopoverClose()
                                                        }}
                                                    >
                                                        <img
                                                            className="object-contain w-16 h-12 mx-auto logo-icons-sm"
                                                            src={explore_icon}
                                                            alt={title}
                                                        ></img>
                                                    </Popover.Button>
                                                )
                                            }
                                        )}
                                    </div>
                                </article>
                                <div className="grid w-full h-full pt-0.5 overflow-hidden lg:grid-cols-5 sm:grid-cols-2 gap-x-2 gap-y-4 lg:flex-auto">
                                    {products.map((item) => (
                                        <div
                                            key={item.name}
                                            className="relative px-6 pb-6 text-sm leading-6 rounded-lg group "
                                        >
                                            <h4 className="block text-base font-extrabold uppercase font-openSans">
                                                {item.name}
                                            </h4>
                                            <ul>
                                                {item.links.map(
                                                    ({ name, link }, index) => (
                                                        <Link
                                                            key={index}
                                                            to={link}
                                                            onClick={() => {
                                                                handlePopoverClose()
                                                            }}
                                                            className="block w-full mt-1 mb-1 text-xs font-semibold text-black border-b border-gray-400 font-openSans hover:opacity-60"
                                                        >
                                                            {name}
                                                        </Link>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    ))}

                                    {/* Resources or Guidelines navigation items*/}

                                    <div className="relative px-6 pb-6 text-sm leading-6 rounded-lg group font-openSans">
                                        <h4 className="block text-base font-extrabold uppercase font-openSans">
                                            {currentFranchise
                                                ? 'Guidelines'
                                                : 'Resources'}
                                        </h4>
                                        <ul>{getLinks()}</ul>
                                    </div>

                                    {!property && (
                                        <div className="relative px-6 pb-6 overflow-auto text-sm leading-6 rounded-lg group font-openSans">
                                            <h4 className="block text-base font-extrabold text-black uppercase font-openSans">
                                                {currentFranchise
                                                    ? 'Properties'
                                                    : currentBrand
                                                    ? 'Franchises'
                                                    : 'Brands'}
                                            </h4>
                                            <ul>
                                                {getFranchisePropertyLinks()}
                                            </ul>
                                        </div>
                                    )}
                                    <div className="relative px-6 pb-6 leading-6 uppercase rounded-lg group ">
                                        <Popover.Button
                                            as={Link}
                                            to="#"
                                            className="block text-base font-extrabold text-black font-openSans hover:opacity-60"
                                            onClick={() => {
                                                handlePopoverClose()
                                            }}
                                        >
                                            Downloads
                                        </Popover.Button>
                                        <Popover.Button
                                            as={Link}
                                            to="#"
                                            className="block text-base font-extrabold text-black font-openSans hover:opacity-60"
                                            onClick={() => {
                                                handlePopoverClose()
                                            }}
                                        >
                                            WB News
                                        </Popover.Button>
                                        <Popover.Button
                                            as={Link}
                                            to="#"
                                            className="block text-base font-extrabold text-black font-openSans hover:opacity-60"
                                            onClick={() => {
                                                handlePopoverClose()
                                            }}
                                        >
                                            Contacts
                                        </Popover.Button>
                                        <Popover.Button
                                            onClick={() => {
                                                handlePopoverClose()
                                            }}
                                            className="block text-base font-extrabold text-left text-black uppercase font-openSans hover:opacity-60"
                                        >
                                            <a
                                                href="https://airtable.com/shrArcqv6NTaC8NHg"
                                                target="_blank"
                                            >
                                                Feedback
                                            </a>
                                        </Popover.Button>
                                    </div>
                                    <div className="relative px-6 pb-6 text-sm leading-6 rounded-lg group ">
                                        <Popover.Button
                                            as={Link}
                                            onClick={() => {
                                                handlePopoverClose()
                                            }}
                                            to="https://wb.lightning.force.com/lightning/page/home"
                                            className="block text-base font-extrabold text-black uppercase font-openSans hover:opacity-60"
                                        >
                                            Seeker Home
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                        {/* </Transition> */}
                    </>
                )}
            </Popover>
        </Popover.Group>
    )
}

export default NavPopover
