import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { loadableFranchisesByNameAtom } from 'utils/atoms'
import { useLocation } from 'react-router-dom'

const useGetCurrentLevelNav = () => {
    const { brand, franchise, property } = useParams()
    const { pathname } = useLocation()
    const [{ data: franchisesByName }] = useAtom(loadableFranchisesByNameAtom)
    const [currentBrand, setCurrentBrand] = useState(null)
    const [currentFranchises, setCurrentFranchises] = useState(null)
    const [currentFranchise, setCurrentFranchise] = useState(null)
    const [currentFranchiseProperties, setCurrentFranchiseProperties] =
        useState(null)
    const [currentFranchiseArticles, setCurrentFranchiseArticles] = useState([])
    const [currentSelection, setCurrentSelection] = useState(null)

    useEffect(() => {
        if (!brand || !franchisesByName) {
            setCurrentSelection(null)
            setCurrentFranchise(null)
            return
        }

        setCurrentBrand(franchisesByName[brand])
        setCurrentFranchises(franchisesByName[brand]?.children)

        if (franchise) {
            const currentFranchiseObj = franchisesByName[brand]?.children.find(
                (f) => f.slug.split('/').at(-1) === franchise
            )

            setCurrentFranchise(currentFranchiseObj)
            setCurrentFranchiseArticles(currentFranchiseObj?.nav)
            const franchiseProperties = currentFranchiseObj?.children.filter(
                (franchise) => franchise.type === 'property'
            )

            setCurrentFranchiseProperties(franchiseProperties)

            const cProperty = property
                ? franchiseProperties?.find(
                      (fProperty) =>
                          fProperty.slug.split('/').at(-1) === property
                  )
                : null

            setCurrentSelection(cProperty || currentFranchiseObj)

            return
        }

        setCurrentFranchise(null)
        setCurrentFranchiseArticles(null)
        setCurrentFranchiseProperties(null)
        setCurrentSelection(() => franchisesByName[brand])
    }, [franchisesByName, pathname])

    return {
        currentBrand,
        currentFranchises,
        currentFranchise,
        currentFranchiseArticles,
        currentSelection,
        currentFranchiseProperties,
    }
}

export default useGetCurrentLevelNav
