export const renderImage = (filename) => {
    return `/images/${filename}`
}

export const debounce = (func, timeout = 300) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

export const flat = (array) => {
    var result = []
    array.forEach(function (a) {
        result.push(a)
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children))
        }
    })
    return result
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const classify = (str) => {
    return str.toLowerCase().replaceAll(' ', '-')
}

export const objectToQueryParams = (object) => {
    return new URLSearchParams(object).toString()
}

export const jsonOrFalse = (str) => {
    try {
        return JSON.parse(str)
    } catch (e) {
        return false
    }
}
