import React from 'react'
import WbLogo from '__shared/images/icons/black-wb-logo.png'
import { Link } from 'react-router-dom'

const FranchisePropertyCards = ({
    franchise,
    isVisible,
    setShowAll,
    showAll,
    additionalCards,
    isErrorImg,
}) => {
    const cardStyle = {
        maxHeight: isVisible ? '385px' : '0',
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        opacity: isVisible ? '1' : '0',
        // zIndex: additionalCards ? '-1' : '1',
    }
    return (
        <article
            className={`${
                additionalCards && !showAll ? 'mb-0' : 'mb-10'
            } bg-white rounded-[4px] pointer overflow-hidden relative group transition-all`}
            onClick={() => {
                setShowAll(false)
            }}
            style={cardStyle}
        >
            <Link to={`${franchise.slug}`}>
                <div className="w-full overflow-hidden lg:aspect-none group-hover:opacity-80 h-60 min-h-60 aspect-h-1 aspect-w-1 lg:h-60">
                    <img
                        src={franchise.featured_image || WbLogo}
                        onError={getFallbackImg}
                        alt={franchise.title}
                        className={`${
                            isErrorImg ? 'object-contain' : 'object-cover'
                        } w-full h-full card-img`}
                    ></img>
                </div>
                <div className="pt-0.5">
                    <img
                        src={franchise.header_icon}
                        className="block object-cover h-12 mx-auto"
                    ></img>
                </div>
                <div className="">
                    <h4 className="mt-2 mb-2 font-bold text-center text-black uppercase font-openSans">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {franchise.title}
                    </h4>
                </div>
            </Link>
        </article>
    )
}

export default FranchisePropertyCards

const getFallbackImg = (e) => {
    e.target.onerror = null
    e.target.src = WbLogo
    e.target.srcet = WbLogo
}
