import { useState } from 'react'
import './support.scss'
import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'
import { typeOptions } from 'utils/constants'
import { useLocation } from 'react-router-dom'

export default function Support() {
    const location = useLocation()
    const [formValues, setFormValues] = useState({
        name: '',
        subject: '',
        email: '',
        contract: '',
        description: '',
        type: '',
    })

    const fieldChange = ({ name, value }) => {
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const validateSupportForm = (e) => {
        setFormValues({
            ...formValues,
            description: `${formValues.contract} - ${formValues.description}`,
        })
        const errors = Object.keys(formValues).filter(
            (item) => formValues[item].length < 1
        )

        if (errors.length > 0) {
            e.preventDefault()
        }
    }

    return (
        <div className="w-full mx-auto support standard-page max-w-8xl">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>

            <main className="min-h-page">
                {location.search.indexOf('success') > -1 ? (
                    <aside>
                        <h3 className="text-4xl my-9">Thank you</h3>

                        <p>
                            Your support request has been submitted, and a
                            member of the Seeker Support team will reach out to
                            you.{' '}
                        </p>
                    </aside>
                ) : (
                    <div>
                        <aside>
                            <h3 className="text-4xl my-9">Support</h3>
                            <p className="my-4">
                                Use the following form to reach out to Seeker
                                Support
                            </p>
                        </aside>

                        <form
                            action={process.env.REACT_APP_SF_CASE_FORM_HREF}
                            method="POST"
                            onSubmit={(e) => validateSupportForm(e)}
                            className="max-w-lg space-y-4 text-sm font-arial"
                        >
                            <input
                                required
                                type="hidden"
                                name="orgid"
                                value={process.env.REACT_APP_SF_ORG_ID}
                            />
                            <input
                                required
                                type="hidden"
                                name="retURL"
                                value="https://seeker.warnerbros.com/support?success"
                            />
                            <input
                                required
                                type="hidden"
                                id="external"
                                name="external"
                                value="1"
                            />
                            <input 
                                type="hidden" 
                                name="recordType" 
                                id="recordType" 
                                value="012Hs000001AI9EIAW" 
                            />
                            <fieldset>
                                <label htmlFor="name">Your Name</label>
                                <input
                                    required
                                    id="name"
                                    maxLength="80"
                                    name="name"
                                    size="20"
                                    type="text"
                                    value={formValues.name}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>
                            <fieldset>
                                <label htmlFor="email">Email</label>
                                <input
                                    required
                                    id="email"
                                    maxLength="80"
                                    name="email"
                                    size="20"
                                    type="text"
                                    value={formValues.email}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>

                            <fieldset>
                                <label htmlFor="subject">Contract No</label>
                                <span className="text-xs helptext">
                                    A contract number is required to locate your
                                    account information
                                </span>
                                <input
                                    required
                                    name="contract"
                                    type="text"
                                    value={formValues.contract}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>

                            <fieldset>
                                <label htmlFor="subject">Subject</label>
                                <input
                                    required
                                    id="subject"
                                    maxLength="80"
                                    name="subject"
                                    size="20"
                                    type="text"
                                    value={formValues.subject}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>

                            <fieldset>
                                <label htmlFor="description">Description</label>
                                <textarea
                                    required
                                    rows="4"
                                    name="description"
                                    value={formValues.description}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                ></textarea>
                            </fieldset>

                            <fieldset>
                                <label htmlFor="type">Type</label>
                                <select
                                    required
                                    id="type"
                                    name="type"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                    value={formValues.type}
                                    className="text-black py-3 px-1.5"
                                >
                                    <option value="">----</option>
                                    {typeOptions.map(({ label, value }) => {
                                        return (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        )
                                    })}
                                </select>
                            </fieldset>

                            <fieldset>
                                <input
                                    className="py-3 mx-auto mt-6 border-2 border-slate-700 btn btn-style px-9"
                                    type="submit"
                                    name="submit"
                                />
                            </fieldset>
                        </form>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    )
}
