import React from 'react'
import { classify } from '../../../../utils/utils.js'
import { ReactComponent as Arrow } from '../../../../__shared/images/icons/icon-forward-arrow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faFilm } from '@fortawesome/free-solid-svg-icons'
const TableRow = ({
    headers,
    event_category,
    categoryTypes,
    fCategories,
    sortedFCategories,
    setSelectedEvent,
    themes,
}) => {
    const getThemesByName = () => {
        const themesByName = {}
        themes.forEach(({ name, ...rest }) => {
            themesByName[name] = { ...rest }
        })
        return themesByName
    }
    const themesByName = getThemesByName()

    const handleKeyPress = (e, event) => {
        if (e.key === ' ' || e.keyCode == 13 || e.key == 'Enter') {
            setSelectedEvent(event)
        }
    }
    const iconMappings = {
        Public: <FontAwesomeIcon icon={faBullhorn} color="white" />,
        'Theatrical Tentpole': <FontAwesomeIcon icon={faFilm} color="white" />,
    }
    return (
        <>
            {categoryTypes[event_category].length > 0 ? (
                categoryTypes[event_category].map((event, eventIndex) => {
                    const evClasses = Object.keys(event.metadata).reduce(
                        (p, k) => {
                            if (typeof k === 'string') {
                                return `${p} ${classify(
                                    `${k}-${event.metadata[k]}`
                                )} `
                            }
                            return
                        },
                        ''
                    )
                    const isMulti = event.end_index - event.start_index
                    let limit = headers?.length
                    const { start_index, image } = event
                    return (
                        <tr
                            className={`${
                                eventIndex > 0 ? 'hide-header' : ''
                            } `}
                            key={eventIndex}
                            data-id={event.ID}
                        >
                            <th
                                className={`${
                                    eventIndex ===
                                    categoryTypes[event_category].length - 1
                                        ? 'pb-1'
                                        : ''
                                } leading-4 h-[42px] p-0 pr-2 category-row border-r-2 border-solid `}
                            >
                                <div
                                    className={`${
                                        eventIndex === 0 &&
                                        eventIndex ===
                                            categoryTypes[event_category]
                                                .length -
                                                1
                                            ? 'rounded-tr-md rounded-tl-md rounded-br-md rounded-bl-md'
                                            : eventIndex ===
                                              categoryTypes[event_category]
                                                  .length -
                                                  1
                                            ? 'rounded-br-md rounded-bl-md'
                                            : eventIndex === 0
                                            ? 'rounded-tr-md rounded-tl-md'
                                            : ''
                                    } justify-center h-full font-medium text-center bg-gray100 flex items-center gap-2 px-2 py-0.5`}
                                >
                                    <span className="uppercase">
                                        {event_category}
                                    </span>
                                    {iconMappings[event_category] && (
                                        <div className="p-[5px] rounded-full bg-lightslate icon-container">
                                            {iconMappings[event_category]}
                                        </div>
                                    )}
                                </div>
                            </th>
                            {headers.map((header, headerIndex) => {
                                if (headerIndex > limit - isMulti - 1) return
                                return start_index === headerIndex &&
                                    headerIndex <= limit - isMulti ? (
                                    <td
                                        key={header + headerIndex}
                                        colSpan={isMulti + 1}
                                        className={`${evClasses} ${
                                            event.isAnnounced ? 'announced' : ''
                                        } p-0.5 `}
                                    >
                                        <div className="hoverer ">
                                            <p>{event.post_title}</p>
                                            <p>{event.metadata.start_date}</p>
                                        </div>
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            onClick={() =>
                                                setSelectedEvent(event)
                                            }
                                            onKeyDown={(e) =>
                                                handleKeyPress(e, event)
                                            }
                                            className={`${
                                                image[0] &&
                                                event.metadata.show_icon &&
                                                !event.metadata.show_name
                                                    ? 'h-[38px]'
                                                    : 'max-h-[38px]'
                                            } bg-white rounded-md drop-shadow overflow-hidden`}
                                        >
                                            {image[0] &&
                                            event.metadata.show_icon &&
                                            !event.metadata.show_name ? (
                                                <img
                                                    className="object-contain w-full max-h-16 image-preview"
                                                    src={image[0]}
                                                />
                                            ) : event.metadata.show_name &&
                                              !event.metadata.show_icon ? (
                                                <div className="w-full overflow-hidden">
                                                    <p className="break-words leading-4.5 p-0.5 gap-3 justify-center items-center text-center flex flex-wrap text-sm ">
                                                        {event.post_title}
                                                        {(event.isMultiYear ||
                                                            event.isMultiCalendarWeek) && (
                                                            <span>
                                                                <Arrow />
                                                            </span>
                                                        )}
                                                        {event.isSameDay && (
                                                            <span>
                                                                {
                                                                    event
                                                                        .metadata
                                                                        .start_date
                                                                }
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="w-full overflow-hidden">
                                                    <div className="flex items-center justify-center gap-1 overflow-hidden text-sm leading-4.5 tracking-sm break-word max-h-[38px] ">
                                                        {image[0] &&
                                                            event.metadata
                                                                .show_icon && (
                                                                <img
                                                                    className="object-contain h-full max-h-[38px]"
                                                                    src={
                                                                        image[0]
                                                                    }
                                                                />
                                                            )}
                                                        <p className="max-h-[38px]">
                                                            {event.post_title}
                                                        </p>
                                                        {(event.isMultiYear ||
                                                            event.isMultiCalendarWeek) && (
                                                            <span>
                                                                <Arrow />
                                                            </span>
                                                        )}
                                                        {event.isSameDay && (
                                                            <span>
                                                                {
                                                                    event
                                                                        .metadata
                                                                        .start_date
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                ) : (
                                    <td
                                        className="empty"
                                        key={headerIndex}
                                    ></td>
                                )
                            })}
                        </tr>
                    )
                })
            ) : (
                <tr
                // className={`${eventIndex > 0 ? 'hide-header' : ''} `}
                // data-id={event.ID}
                >
                    <th className="pb-1 leading-4 h-[42px] p-0 pr-2 category-row border-r-2 border-solid">
                        <div className="rounded-tr-md rounded-tl-md rounded-br-md rounded-bl-md justify-center h-full font-medium text-center bg-gray100 flex items-center gap-2 px-2 py-0.5">
                            <span className="uppercase">{event_category}</span>
                            {iconMappings[event_category] && (
                                <div className="p-[5px] rounded-full bg-lightslate icon-container">
                                    {iconMappings[event_category]}
                                </div>
                            )}
                        </div>
                    </th>
                    {headers.map((header, headerIndex) => (
                        <td className="empty" key={headerIndex}></td>
                    ))}
                </tr>
            )}

            {sortedFCategories[event_category] &&
                sortedFCategories[event_category].map(
                    (fcategory, fcategoryIndex) => (
                        <React.Fragment key={fcategory}>
                            {fCategories[event_category][fcategory].map(
                                (event, eventIndex) => {
                                    const evClasses = Object.keys(
                                        event.metadata
                                    ).reduce((p, k) => {
                                        if (typeof k === 'string') {
                                            return `${p} ${classify(
                                                `${k}-${event.metadata[k]}`
                                            )} `
                                        }
                                        return
                                    }, '')
                                    const isMulti =
                                        event.end_index - event.start_index
                                    let limit = headers?.length
                                    const { start_index, image } = event

                                    return (
                                        <tr
                                            className={`${
                                                eventIndex > 0
                                                    ? 'hide-header'
                                                    : ''
                                            }`}
                                            key={eventIndex}
                                            data-id={event.ID}
                                        >
                                            <th
                                                className={`${
                                                    eventIndex ===
                                                        fCategories[
                                                            event_category
                                                        ][fcategory].length -
                                                            1 && 'pb-1'
                                                } h-[42px] p-0 pr-2  border-r-2 border-solid category-row border-lightergray`}
                                            >
                                                <div
                                                    className={`${
                                                        eventIndex === 0 &&
                                                        eventIndex ===
                                                            fCategories[
                                                                event_category
                                                            ][fcategory]
                                                                .length -
                                                                1
                                                            ? 'rounded-tr-md rounded-tl-md rounded-br-md rounded-bl-md'
                                                            : eventIndex ===
                                                              fCategories[
                                                                  event_category
                                                              ][fcategory]
                                                                  .length -
                                                                  1
                                                            ? 'rounded-br-md rounded-bl-md'
                                                            : eventIndex === 0
                                                            ? 'rounded-tr-md rounded-tl-md'
                                                            : ''
                                                    } justify-center h-full font-medium text-center bg-gray100 flex items-center gap-2 px-2 py-0.5`}
                                                >
                                                    <span className="leading-4.5 uppercase text-right">
                                                        {fcategory}
                                                    </span>
                                                    {iconMappings[fcategory] ? (
                                                        <div className=" p-[5px] rounded-full bg-lightslate icon-container">
                                                            {
                                                                iconMappings[
                                                                    fcategory
                                                                ]
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="p-[13px]"></div>
                                                    )}
                                                </div>
                                            </th>
                                            {headers.map(
                                                (header, headerIndex) => {
                                                    if (
                                                        headerIndex >
                                                        limit - isMulti - 1
                                                    )
                                                        return
                                                    return start_index ===
                                                        headerIndex &&
                                                        headerIndex <=
                                                            limit - isMulti ? (
                                                        <td
                                                            key={
                                                                header +
                                                                headerIndex
                                                            }
                                                            colSpan={
                                                                isMulti + 1
                                                            }
                                                            className={`${evClasses} ${
                                                                event.isAnnounced
                                                                    ? 'announced'
                                                                    : ''
                                                            } p-0.5 `}
                                                        >
                                                            <div className="hoverer ">
                                                                <p className="capitalize">
                                                                    {
                                                                        event.post_title
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        event
                                                                            .metadata
                                                                            .start_date
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div
                                                                role="button"
                                                                tabIndex={0}
                                                                onClick={() =>
                                                                    setSelectedEvent(
                                                                        event
                                                                    )
                                                                }
                                                                onKeyDown={(
                                                                    e
                                                                ) =>
                                                                    handleKeyPress(
                                                                        e,
                                                                        event
                                                                    )
                                                                }
                                                                className={`${
                                                                    image[0] &&
                                                                    event
                                                                        .metadata
                                                                        .show_icon &&
                                                                    !event
                                                                        .metadata
                                                                        .show_name
                                                                        ? 'h-[38px]'
                                                                        : 'max-h-[38px]'
                                                                } bg-white rounded-md drop-shadow`}
                                                            >
                                                                {image[0] &&
                                                                    event
                                                                        .metadata
                                                                        .show_icon &&
                                                                    !event
                                                                        .metadata
                                                                        .show_name && (
                                                                        <img
                                                                            className="object-contain w-full h-full"
                                                                            src={
                                                                                image[0]
                                                                            }
                                                                        />
                                                                    )}
                                                                {event.metadata
                                                                    .show_name && (
                                                                    <p className="leading-4.5 break-words p-0.5 text-black gap-3 justify-center items-center text-center flex flex-wrap mt-.5 text-sm capitalize ">
                                                                        {image[0] &&
                                                                            event
                                                                                .metadata
                                                                                .show_icon && (
                                                                                <img
                                                                                    className="object-contain w-full h-full"
                                                                                    src={
                                                                                        image[0]
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {
                                                                            event.post_title
                                                                        }
                                                                        {(event.isMultiYear ||
                                                                            event.isMultiCalendarWeek) && (
                                                                            <span>
                                                                                <Arrow />
                                                                            </span>
                                                                        )}
                                                                        {event.isSameDay && (
                                                                            <span>
                                                                                {
                                                                                    event
                                                                                        .metadata
                                                                                        .start_date
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td
                                                            className="empty"
                                                            key={headerIndex}
                                                        ></td>
                                                    )
                                                }
                                            )}
                                        </tr>
                                    )
                                }
                            )}
                        </React.Fragment>
                    )
                )}
        </>
    )
}

export default TableRow
