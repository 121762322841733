import WbLogo from '__shared/images/icons/wb-logo.png'
import { classNames } from 'utils/utils'
export default function Footer({ className }) {
    return (
        <footer
            className={classNames(
                'h-32 text-sm font-openSans pt-4 px-10',
                className
            )}
        >
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="flex flex-col items-start justify-between pt-4 mx-auto text-xs border-t sm:flex-row max-w-8xl gap-y-4">
                <div className="flex items-center gap-x-2 ">
                    <div>
                        <img
                            src={WbLogo}
                            alt="Warner Bros shield logo"
                            className="object-contain w-7"
                        />
                    </div>
                    <p className="leading-4.5 ">
                        TM &amp; &copy; 2023 Warner Bros. Entertainment Inc. All
                        rights reserved.
                    </p>
                </div>
                <div className="flex items-center mx-auto sm:mx-0 gap-x-8">
                    <div>
                        <a
                            className="leading-4.5 hover:opacity-75"
                            target="_blank"
                            href="https://policies.warnerbros.com/terms/en-us/html/terms_en-us_1.2.0.html"
                        >
                            Terms of Use
                        </a>
                    </div>
                    <div>
                        <a
                            className="leading-4.5 hover:opacity-75"
                            target="_blank"
                            href="https://policies.warnerbros.com/privacy/"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
