import React from 'react'
import './BrandFranchiseOverview.scss'
import { useParams, Link } from 'react-router-dom'
import SectionEditButton from '../SectionEditButton/SectionEditButton'
const BrandFranchiseOverview = ({ content }) => {
    const { franchise, property } = useParams()
    if (!content.structured.overview) {
        return null
    }
    return (
        <section
            className="relative mx-auto bg-white overflow-y isolate max-w-8xl "
            id="brand-overview"
        >
            {content.structured.overview_background && (
                <img
                    src={content.structured.overview_background}
                    alt=""
                    className="absolute right-0 object-cover w-1/2 h-full"
                />
            )}
            <SectionEditButton hash="#section-overview" id={content.ID} />
            <div
                className="absolute inset-0 overflow-hidden -z-10 transform-gpu"
                aria-hidden="true"
            >
                {/* <div
                    style={{
                        backgroundColor:
                            content.structured?.overview_background_color,
                    }}
                    className="relative w-full h-full bg-white opacity-60 bg-gradient-to-b"
                /> */}
            </div>
            <div className="relative flex">
                <div
                    className={`overview-branded-font-color px-12 py-12 lg:pl-12 lg:px-0 md:basis-5/12 lg:py-20 lg:basis-5/12 ${
                        franchise || property
                            ? 'bg-black text-white'
                            : 'bg-white'
                    }`}
                >
                    <h1 className="mb-8 text-5xl font-extrabold font-openSans wp-resources-title">
                        Overview
                    </h1>
                    <div
                        className={`wp-content wp-overview md:text-left ${
                            franchise || property ? 'text-white' : 'text-black'
                        }`}
                        dangerouslySetInnerHTML={{
                            __html: content && content.structured.overview,
                        }}
                    />
                </div>
                {/* transition blur effect left to right div */}
                <div className="relative" aria-hidden="true">
                    <div
                        className={`absolute inset-y-0 hidden pr-80 bg-gradient-to-r to-transparent md:block ${
                            franchise || property ? 'from-black' : 'from-white'
                        } `}
                    />
                </div>

                <div className="absolute inset-y-0 right-0 h-full overflow-hidden md:basis-2/5 lg:basis-3/5 md:block -z-10 md:left-2/3 lg:left-1/3">
                    {content.structured.overview_image && (
                        <img
                            src={content && content.structured.overview_image}
                            className="object-cover w-full h-full"
                        ></img>
                    )}
                </div>
            </div>
        </section>
    )
}

export default BrandFranchiseOverview
