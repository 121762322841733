import './product-submission.scss'
import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'

export default function ProductSubmission() {
    return (
        <div className="marketing-submission standard-page z-[9] relative bg-darkerblue mx-auto max-w-8xl">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>
            <main className="relative py-9 z-[4]">
                <h3 className="my-12 text-5xl text-center">
                    Product Submission
                </h3>
                <iframe
                    width="800px"
                    className="mx-auto mt-3"
                    height="3700px"
                    src="https://airtable.com/embed/shrY8P0dwQtICZyZQ?backgroundColor=purpleDusty"
                ></iframe>
            </main>
            <Footer />
        </div>
    )
}
