import { atom } from 'jotai'
import { atomWithStorage, loadable } from 'jotai/utils'

export let navAtom = atom(async (get) => {
    const authToken = getAuthToken()
    const defaultHeaders = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
    }
    const response = await fetch(
        `${process.env.REACT_APP_FRANCHISE_PROXY_URL}/setting/nav`,
        {
            credentials: 'include',
            headers: {
                ...defaultHeaders,
            },
        }
    )
    if (response.status === 401 || response.status === 403) {
        // remove token to prevent loop in login
        removeAuthToken()
        window.location.href = '/login'
    }
    const data = await response.json()
    return data
})

export const userInfoAtom = atom(async (get) => {
    const authToken = getAuthToken()
    const defaultHeaders = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
    }
    const response = await fetch(
        `${process.env.REACT_APP_FRANCHISE_PROXY_URL}/user-info`,
        {
            credentials: 'include',
            headers: {
                ...defaultHeaders,
            },
        }
    )
    if (response.status === 401 || response.status === 403) {
        removeAuthToken()
        window.location.href = '/login'
    }
    const data = await response.json()
    return data
})

export const franchisesByNameAtom = atom(async (get) => {
    const franchises = await get(navAtom)

    const franchisesByName = {}
    franchises.forEach(({ slug, ...franchise }) => {
        const updatedSlug = slug.replace('/franchise/', '')
        franchisesByName[updatedSlug] = {
            slug,
            ...franchise,
        }
    })
    return franchisesByName
})
export const loadableFranchisesByNameAtom = loadable(franchisesByNameAtom)

export const loadableNavAtom = loadable(navAtom)

export const menuAtom = atomWithStorage('menuSelection', {
    level: 1,
})
export const categoriesSelectionAtom = atomWithStorage(
    'eventCategoriesSelection',
    [
        {
            label: 'Public',
            value: 'Public',
        },
    ]
)
export const pinnedNav = atomWithStorage('pinnedNav', false)

export const navHeaderAtom = atom(null)

export const matchesAtom = atom([])
export const searchTermAtom = atom('')
export const isNavHoveredAtom = atom(false)

export const showEditButtonAtom = atom(false)

function getAuthToken() {
    return document.cookie.replace(
        /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
    )
}

function removeAuthToken() {
    document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${
        window.location.hostname !== 'localhost' && 'domain: warnerbros.com;'
    }`
}
