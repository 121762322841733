import { useMemo } from 'react'
import AgendaEventItem from '../AgendaEventItem/AgendaEventItem'

const AgendaMonthItem = ({ header, eventsByMonth }) => {
    const [year, month] = header.split('-')

    const addRelatedEventsToEndOfArray = (events) => {
        const relatedEvents = events.filter(
            (event) => event.metadata.event_category === 'Related'
        )

        return [
            ...events.filter((e) => e.metadata.event_category !== 'Related'),
            ...relatedEvents,
        ]
    }

    const eventsByAscendingDate = eventsByMonth.sort(
        (a, b) =>
            new Date(a.metadata.start_date) - new Date(b.metadata.start_date)
    )
    const updatedEventsByDateAndContentType = useMemo(
        () => addRelatedEventsToEndOfArray(eventsByAscendingDate),
        [eventsByMonth]
    )

    const MONTHSBYINDEX = useMemo(
        () => ({
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec',
        }),
        []
    )

    return (
        <div className="bg-white border-r border-transparent sm:border-[#757575] sm:pr-10">
            <div className="">
                <div className="bg-[#DADADA] text-[#757575] text-lg sm:text-1xl lg:text-3xl tracking-wider uppercase font-bold p-2.5 rounded-[5px] mb-4">
                    {MONTHSBYINDEX[parseInt(month) + 1]} {year}
                </div>
                <div className="flex flex-col gap-2 ml-2 divide-y-2">
                    {updatedEventsByDateAndContentType.map((event) => (
                        <AgendaEventItem
                            event={event}
                            key={event.ID}
                            MONTHSBYINDEX={MONTHSBYINDEX}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AgendaMonthItem
