import { useAtom, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { menuAtom, navHeaderAtom, navAtom } from 'utils/atoms'
import { useLocation, useParams } from 'react-router-dom'

const useCompileNav = () => {
    // const location = useLocation()
    const [navLabel, setNavLabel] = useState('')
    const [navIcon, setNavIcon] = useState('')
    const [navItems, setNavItems] = useState([])
    const [selectedMenus, setSelectedMenus] = useState([])
    const [selection, setSelection] = useState(null)
    const setNavHeader = useSetAtom(navHeaderAtom)
    const [navPlacement, setNavPlacement] = useAtom(menuAtom)
    const [nav] = useAtom(navAtom)

    useEffect(() => {
        if (nav == null || nav?.length === 0) return

        const compileNavItems = () => {
            const _nav = [[...nav]]

            let lastSelection
            try {
                switch (true) {
                    case navPlacement.l0 != null && navPlacement.l1 != null:
                        _nav[1] = _nav[0].find(
                            (el) => el.id === navPlacement.l0
                        ).children

                        lastSelection = _nav[1].find(
                            (el) => el.id === navPlacement.l1
                        )
                        // update data when it comes in as json stringified object instead of object
                        if (
                            lastSelection?.nav.length > 0 &&
                            typeof lastSelection.nav === 'string'
                        ) {
                            lastSelection.nav = JSON.parse(lastSelection.nav)
                        }

                        if (navPlacement.level === 3) {
                            setSelection(lastSelection)
                        }
                        _nav[2] = lastSelection?.children
                        break
                    case navPlacement.l0 != null:
                        lastSelection = nav.find(
                            (el) => el.id === navPlacement.l0
                        )
                        if (navPlacement.level === 2) {
                            setSelection(lastSelection)
                        }
                        _nav[1] = lastSelection.children
                        break
                    default:
                        lastSelection = null
                }
            } catch (e) {
                console.log(e)
            }

            let label = null

            switch (navPlacement.level) {
                case 2:
                    label = _nav[0].find((ni) => ni.id === navPlacement.l0)
                    break
                case 3:
                    label = _nav[1].find((ni) => ni.id === navPlacement.l1)
                    break
                default:
                    label = { title: 'Seeker', icon: null }
                    setNavHeader(null)
            }

            setNavLabel(label?.title)
            setNavIcon(label?.icon)
            setNavItems(_nav)
        }

        compileNavItems()
        setSelectedMenus([])
    }, [navPlacement, nav])

    return {
        navLabel,
        setNavLabel,
        navIcon,
        setNavIcon,
        navPlacement,
        setNavPlacement,
        selectedMenus,
        selection,
        navItems,
        setSelectedMenus,
    }
}

export default useCompileNav
