import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
export default function ErrorPage({ className }) {
    const navigate = useNavigate()
    const c = twMerge(
        'grid px-6 py-24 min-h-franchise-page place-items-center sm:py-32 lg:px-8',
        className
    )
    return (
        <main className={c}>
            <div className="text-center">
                <p className="text-lg font-semibold text-white">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
                    Page not found
                </h1>
                <p className="mt-6 text-base leading-7 text-white">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="flex items-center justify-center mt-10 gap-x-6">
                    <button
                        onClick={() => navigate(-1)}
                        className="rounded-md bg-midblue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-midbluehover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midbluehover ease-linear duration-150"
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </main>
    )
}
