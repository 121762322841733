import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, useRoutes } from 'react-router-dom'
import './index.scss'
import Support from './pages/support/support'
import Home from './pages/home/home'
import MarketingSubmission from './pages/marketing-submission/marketing-submission'
import ProductSubmission from './pages/product-submission/product-submission'
import Message from './pages/message/message'
import ReleaseNotes from 'pages/releaseNotes/releaseNotesPage'
import Calendar from 'pages/calendar/calendar'
import FranchiseHub from 'pages/franchise/franchise-hub'
import BrandFranchisePage from 'pages/brand-franchisePage/BrandFranchisePage.js'
import Login from 'pages/login/login'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'
import ErrorPage from 'pages/error/ErrorPage'
import ArticlePage from 'pages/articlePage/ArticlePage'
import FranchiseBase from 'pages/franchise/components/FranchiseBase/FranchiseBase'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/support" element={<Support />} />
                <Route
                    path="/marketing-submission"
                    element={<MarketingSubmission />}
                />
                <Route
                    path="/message"
                    element={<Message />}
                />
                <Route
                    path="/product-submission"
                    element={<ProductSubmission />}
                />
                <Route element={<ProtectedRoute />}>
                    {/* Calendars */}
                    {[
                        '/franchise/:brand/:franchise/calendar',
                        '/tools/calendar',
                        '/franchise/:brand/calendar',
                    ].map((path, index) => (
                        <Route path={path} element={<Calendar />} key={index} />
                    ))}

                    <Route path="/release-notes" element={<ReleaseNotes />} />

                    <Route path="/franchise" element={<FranchiseBase />}>
                        <Route path="" element={<FranchiseHub />} />
                        <Route
                            path=":brand/:franchise/guidelines"
                            element={<ArticlePage guidelines />}
                        />
                        {[
                            ':brand/c/:page',
                            ':brand/:franchise/c/:page',
                            ':brand/:franchise/:property/c/:page',
                        ].map((path, index) => (
                            <Route
                                path={path}
                                element={<ArticlePage />}
                                key={index}
                            />
                        ))}
                        {[
                            ':brand',
                            ':brand/:franchise',
                            ':brand/:franchise/:property',
                        ].map((path, index) => (
                            <Route
                                path={path}
                                element={<BrandFranchisePage />}
                                key={index}
                            />
                        ))}
                    </Route>
                </Route>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)
