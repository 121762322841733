import React, { useEffect } from 'react'
import { ReactComponent as MonthlyCalendar } from '__shared/images/icons/monthly-calendar.svg'
import { ReactComponent as WeeklyCalendar } from '__shared/images/icons/weekly-calendar.svg'
import { ReactComponent as AgendaCalendar } from '__shared/images/icons/agenda-calendar.svg'
import CategoryTypeSelectContainer from './CategoryTypeSelectContainer/CategoryTypeSelectContainer'

const Nav = ({
    setView,
    view,
    toggleMenu,
    capture,
    activeFranchise,
    favorite,
    sortedCategoryTags,
    selectedCategories,
    setSelectedCategories,
    userAllowedEventCategories,
    eventsCountByYearView,
}) => {
    const colorsByEventCategoryType = {
        WBD: '#ef4444',
        Financials: '#d97706',
        Releases: '#84cc16',
        'Live Events': '#10b981',
        'Related/External': '#06b6d4',
        Public: '#0ea5e9',
        Theme: '#6366f1',
        'Home Entertainment': '#a855f7',
        Podcast: '#ec4899',
        Publishing: '#f43f5e',
        Games: '#a21caf',
        'Made For Video': '#6d28d9',
        Film: '#1d4ed8',
        Series: '#0e7490',
        'Specials (i.e., Holiday Specials)': '#047857',
        'Short Form (Unboxing)': '#ea580c',
    }
    const calendarViewOptions = [
        {
            name: 'agenda',
            icon: <AgendaCalendar />,
        },
        {
            name: 'week',
            icon: <WeeklyCalendar />,
        },
        {
            name: 'year',
            icon: <MonthlyCalendar />,
        },
        {
            name: 'multi-year',
            icon: <MonthlyCalendar />,
        },
    ]

    // const color =
    //     activeFranchise && activeFranchise.metadata.color
    //         ? activeFranchise.metadata.color[0]
    //         : '#004eb4'
    return (
        <nav
            id=""
            className="py-10 bg-white "
            // style={{ borderColor: color }}
        >
            {/* <div className="left"> */}
            {/* <div className="hamburger-menu" onClick={() => toggleMenu()}>
          <span></span>
          <span></span>
          <span></span>
        </div> */}
            {/* {activeFranchise ? (
                    <div className="franchise-image-container">
                        <img src={activeFranchise.image[0]} />
                    </div>
                ) : (
                    <></>
                )} */}
            {/* <div className="active-franchise">
          {activeFranchise ? activeFranchise.post_title : ""}
        </div> */}
            {/* </div> */}
            <div className="flex flex-col items-center justify-center gap-4 px-10 mx-auto sm:justify-between lg:flex-row">
                <div>
                    <CategoryTypeSelectContainer
                        userAllowedEventCategories={userAllowedEventCategories}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        eventsCountByYearView={eventsCountByYearView}
                    />
                    {view === 'agenda' && sortedCategoryTags?.length > 0 ? (
                        <div className="mt-4">
                            <ul className="flex items-center gap-2.5 flex-wrap max-w-3xl">
                                {sortedCategoryTags?.map((event, i) => (
                                    <li
                                        key={i}
                                        className="px-3.5 py-0.5 rounded font-bold text-white uppercase text-center"
                                        style={{
                                            backgroundColor:
                                                colorsByEventCategoryType[
                                                    event
                                                ],
                                        }}
                                    >
                                        {event}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="hidden lg:block"></div>
                    )}
                </div>

                {/* <div className="flex items-center px-4 py-4 rounded gap-x-6 bg-lightergray">
                    {calendarViewOptions.map(({ name, icon }) => (
                        <button
                            key={name}
                            className={`${
                                view === name ? '' : 'opacity-60'
                            } flex gap-x-2.5 items-center hover:opacity-75`}
                            onClick={() => {
                                setView(name)
                            }}
                        >
                            <h2 className="m-0 text-base font-bold tracking-wider text-lightgray">
                                {name}
                            </h2>
                            {icon}
                        </button>
                    ))}
                </div> */}
            </div>
        </nav>
    )
}

export default Nav
