import { useState, useEffect, useCallback } from 'react'
import { renderImage } from '../../../../utils/utils'
import { slides } from '../../../../utils/constants'
import './HeroCarousel.scss'
const HeroCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const isSlideActive = (slideIndex) => {
        return slideIndex === currentIndex ? 'active-slide' : ''
    }

    const changeSlide = (direction) => {
        let newIndex = currentIndex + direction
        if (newIndex === slides.length || newIndex === -1) {
            newIndex = 0
        }
        setCurrentIndex(newIndex)
    }

    const callBackChangeSlide = useCallback(changeSlide, [changeSlide])

    useEffect(() => {
        const interval = setInterval(() => {
            callBackChangeSlide(1)
        }, 6e3)
        return () => clearInterval(interval)
    }, [callBackChangeSlide])
    return (
        <>
            <aside className="max-w-4xl mb-16 mt-44">
                <h1 className="text-[7.25rem] my-3 leading-none uppercase">
                    WELCOME TO SEEKER!
                </h1>
                <h3 className="max-w-lg my-5 text-lg tracking-wider">
                    The new licensing management and collaboration portal
                    brought to you by Warner Bros. Consumer Products
                </h3>
            </aside>
            <div className="carousel">
                <div className="carousel-controls">
                    <div
                        className="px-5 prev-slide carousel-control"
                        onClick={() => changeSlide(-1)}
                    >
                        <span className="font-attaleck">&lsaquo;</span>
                    </div>
                    <div
                        className="px-5 next-slide carousel-control"
                        onClick={() => changeSlide(1)}
                    >
                        <span className="font-attaleck">&rsaquo;</span>
                    </div>
                </div>
                <ul className="slides">
                    {slides.map(({ img, alt }, index) => {
                        return (
                            <li
                                key={index}
                                className={`slide-container ${isSlideActive(
                                    index
                                )}`}
                            >
                                <div className="slide-image">
                                    <img src={renderImage(img)} alt={alt} />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default HeroCarousel
