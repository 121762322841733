import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'
import { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/20/solid'
import Loading from 'components/Loading/Loading'
import { useCookies } from 'react-cookie'

export default function Message() {

    return (
        <div className="w-full mx-auto login standard-page">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>
            <main style={{maxWidth : '50%'}} className="flex flex-col items-center mx-auto text-black min-h-page">
                <h5 className="text-4xl text-white my-9">We are working hard to improve Seeker and are in a maintenance window until Feb 12. Communications will be sent out in advance of the next forecast submission. </h5>
            </main>
            <Footer />
        </div>
    )
}