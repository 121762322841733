import React, { useState } from 'react'
import Select from 'react-select'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBlackboard, faCheck } from '@fortawesome/free-solid-svg-icons'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as FilterSolidIcon } from '__shared/images/icons/filter-solid.svg'

const CategoryTypeSelectContainer = ({
    userAllowedEventCategories,
    setSelectedCategories,
    selectedCategories,
    eventsCountByYearView,
}) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '4px',
            borderColor: 'transparent',
            backgroundColor: '#EEEEEE',
            minHeight: '28px',
            height: '28px',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0074e8',
            fontWeight: 700,
        }),
        valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            height: '28px',
            margin: 0,
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: '#0074e8',
            padding: 0,
            marginTop: '4px',
            marginBottom: '4px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'black',
            background: 'transparent',
            width: '100%',
            ':hover': {
                color: '#0074e8',
            },
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: '0px',
            color: '#0074e8',
            height: '28px',
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            padding: '0px',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0074e8',
            padding: '6px',
            ':hover': {
                color: '#0074e8',
            },
        }),
        clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0074e8',
            padding: '6px',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '1px',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: 0,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#0074e8',
        }),
    }

    const handleChange = (selectedValues) => {
        setSelectedCategories(selectedValues)
    }
    const formatOptionLabel = ({ label }) => (
        <div className="flex w-full">
            <input
                type="checkbox"
                className="w-auto mr-2"
                checked={selectedCategories.some(
                    (option) => option.label === label
                )}
                readOnly
            />
            <span className="w-full">{label}</span>
        </div>
    )

    return (
        <div className="flex items-center gap-1 font-bold tracking-wider capitalize font-wbSans">
            <FilterSolidIcon className="w-4 h-4" />
            Filter Events ({eventsCountByYearView})
            <Select
                isMulti
                options={userAllowedEventCategories.map((i) => ({
                    value: i,
                    label: i,
                }))}
                value={selectedCategories}
                onChange={handleChange}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                className=" w-52"
            />
        </div>
    )
}

export default CategoryTypeSelectContainer
