import { ReactComponent as BusinessPlanIcon } from '__shared/images/icons/business-plan.svg'
import { ReactComponent as BrandsFranchisesIcon } from '__shared/images/icons/brands-franchises.svg'
import { ReactComponent as DealManagementIcon } from '__shared/images/icons/deal-management.svg'
import { ReactComponent as RoyaliesIcon } from '__shared/images/icons/royalties.svg'
import { ReactComponent as PaymentsIcon } from '__shared/images/icons/payments.svg'
import { ReactComponent as ForecastingIcon } from '__shared/images/icons/bar-chart.svg'
import { ReactComponent as AccountMgmtIcon } from '__shared/images/icons/account-management.svg'

const slides = [
    {
        img: 'DC1.jpg',
        alt: 'Characters from the DC Universe flying through space',
    },
    {
        img: 'GOT1.jpg',
        alt: 'The Night King of the White Walkers from the Game of Thrones series staring intently',
    },
    {
        img: 'RAM1.jpg',
        alt: 'Rick &amp; Morty characters, Morty climbing a mountain with Rick watching and eating a sandwich. ',
    },
    {
        img: 'DC2.jpg',
        alt: 'Batman looking over Gotham City at sunrise',
    },
    {
        img: 'SD1.jpg',
        alt: 'Scooby-Doo characters with a scared reaction',
    },
    {
        img: 'LT1.jpg',
        alt: "Looney Tunes Sylvester the Cat and Tweety, with Sylvester lunging at Tweety. Badge reading '80 Years of Tweety'",
    },
]

const features = [
    {
        icon: <ForecastingIcon />,
        headline: 'Forecasts',
        subheadline: '(now available)',
        description:
            'Seeker serves as your forecasting hub to dynamically review, submit and process your forecasts',
    },
    {
        icon: <AccountMgmtIcon />,
        headline: 'Account Management',
        subheadline: '(now available)',
        description:
            "Seeker enhances WBCP's relationships with its partners by having all key information in one place",
    },
    {
        icon: <BrandsFranchisesIcon />,
        headline: 'Brands & Franchises',
        subheadline: '(coming soon)',
        description:
            "Whether it's forming a new business plan or updating a contract, Seeker will be the authoritative source for your business needs.",
    },
    {
        icon: <BusinessPlanIcon />,
        headline: 'Business Planning',
        subheadline: '(coming soon)',
        description:
            'A WBCP business plan is a strategic document that establishes the vision and purpose of a potential partnership.',
    },
    {
        icon: <DealManagementIcon />,
        headline: 'Deal Management',
        subheadline: '(coming soon)',
        description:
            'Powered by a new Contract Lifecycle Management system, WBCP and its partners will be fully equipped to execute contacts effectively.',
    },
    {
        icon: <RoyaliesIcon />,
        headline: 'Royalties',
        subheadline: '(coming soon)',
        description:
            'Seeker will simplify the way WBCP connects with our licensing partners to collect royalty information.',
    },
    {
        icon: <PaymentsIcon />,
        headline: 'Payments',
        subheadline: '(coming soon)',
        description:
            'Seeker’s payment center will be the one source to locate invoices, inquire about status, and communicate with WBCP finance.',
    },
]

const typeOptions = [
    {
        label: 'Access/Login',
        value: 'Access/Login',
    },
    {
        label: 'Technical Issue',
        value: 'Technical Issues',
    },
    {
        label: 'Training',
        value: 'Training',
    },
    {
        label: 'Feature Request',
        value: 'Feature Request',
    },
    {
        label: 'Account/Contract',
        value: 'Account/Contract',
    },
    {
        label: 'Product Submission (UPC)',
        value: 'Product Submission (UPC)',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]

const globalNavLinks = [
    {
        name: 'Partner Login',
        target: '_blank',
        rel: 'noreferrer',
        href: 'https://wbcp.my.site.com/cptech/login',
        className: 'block login sm:w-auto',
    },
    {
        name: 'Franchise',
        target: '_blank',
        rel: 'noreferrer',
        href: '/franchise',
        className: 'block login sm:w-auto',
    },
    {
        name: 'Marketing',
        href: '/marketing-submission',
        className: 'sm:w-auto',
    },
    {
        name: 'Product',
        target: '_blank',
        rel: 'noreferrer',
        href: 'https://airtable.com/embed/appYv1QT5OFRaD6Pk/shrkOdof066WtFKrT',
        className: 'sm:w-auto',
    },
    {
        name: 'WBD Employee Login',
        target: '_blank',
        rel: 'noreferrer',
        href: 'https://wbcp.my.salesforce.com',
        className: 'login sm:w-auto',
    },
    {
        name: 'Support',
        href: '/support',
        className: 'sm:w-auto',
    },
]
export { slides, features, typeOptions, globalNavLinks }
