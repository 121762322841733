import React from 'react'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'
import { ReactComponent as OverviewIcon } from '__shared/images/icons/overview.svg'
import { ReactComponent as GuidelinesIcon } from '__shared/images/icons/guidelines.svg'
import { ReactComponent as FranchisesIcon } from '__shared/images/icons/franchises.svg'
import { ReactComponent as DownloadsIcon } from '__shared/images/icons/downloads.svg'
import './BrandFranchiseNavLinks.scss'

const BrandFranchiseNavLinks = ({ content }) => {
    const { currentSelection } = useGetCurrentLevelNav()

    const navLinks = [
        {
            name: 'Overview',
            icon: <OverviewIcon />,
            link: '#brand-overview',
        },
        {
            name: 'Guidelines',
            icon: <GuidelinesIcon />,
            link: '#brand-guidelines',
        },
    ]

    // Add properties/franchise link only if not on property page
    if (currentSelection?.type != 'property') {
        navLinks.push({
            name:
                currentSelection?.type === 'franchise'
                    ? 'Properties'
                    : 'Franchises',
            icon: <FranchisesIcon />,
            link: '#brand-franchises',
        })
    }

    // Add resources last
    navLinks.push({
        name: 'Resources',
        icon: <DownloadsIcon />,
        link: '#brand-resources',
    })

    const generatedLinks = navLinks.map((navLink) => {
        if (navLink.name === 'Overview' && !content.structured.overview) {
            return
        }
        return (
            <a
                key={navLink.name}
                href={navLink.link}
                className="z-10 justify-center sm:w-44 lg:w-52 px-3 py-5 text-center text-white duration-100 ease-linear border border-white pointer mx-autoitems-center rounded-3xl hover:bg-darkblue "
            >
                <article key={navLink.name} className="mx-3 sm:mx-0">
                    <div className="flex items-center justify-center h-14 sm:h-20 auto icon-container">
                        {navLink.icon}
                    </div>
                    <h3 className="pt-6 text-base sm:text-3xl g:text-4xl font-bold tracking-wider uppercase">
                        {navLink.name}
                    </h3>
                </article>
            </a>
        )
    })

    return (
        <section className="py-16 brand-navlinks">
            <div className="px-4 mx-auto max-w-8xl sm:px-8">
                <div className="flex flex-wrap items-stretch justify-center gap-4 sm:gap-10 ">
                    {generatedLinks}
                </div>
            </div>
        </section>
    )
}

export default BrandFranchiseNavLinks
