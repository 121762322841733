import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '__shared/images/icons/house-user-solid.svg'
import { twMerge } from 'tailwind-merge'

const Breadcrumbs = ({ className }) => {
    const location = useLocation()
    const paths = location.pathname
        .replace('/c/', '/')
        .split('/')
        .filter((path) => path !== '')
        .map((path) => path.replaceAll('-', ' '))
    const c = twMerge(
        'breadcrumbs relative z-20 flex items-center pl-12 text-lg font-bold uppercase breadcrumbs text-blue font-wbs gap-x-2',
        className
    )

    return (
        <div className={c}>
            {paths.map((path, index) => {
                const isLastPath = index === paths.length - 1
                return (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            <span className="text-wbgray">&#62;</span>
                        )}
                        {isLastPath ? (
                            <span className="text-wbgray last-path">
                                {path}
                            </span>
                        ) : (
                            <Link
                                to={`/${paths
                                    .slice(0, index + 1)
                                    .join('/')
                                    .replaceAll(' ', '-')}`}
                                className="flex items-center gap-x-2 paths"
                            >
                                {index === 0 && (
                                    <HomeIcon className="w-4 fill-blue" />
                                )}
                                {path}
                            </Link>
                        )}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default Breadcrumbs
