import React from 'react'
import { ReactComponent as CaretRightIcon } from '__shared/images/icons/caret.svg'

const YearlyFilters = ({
    view,
    currentStartFilterIndex,
    currentEndFilterIndex,
    years,
    handleStartDateFilterClick,
    handleEndDateFilterClick,
}) => {
    return (
        <div>
            {view !== 'multi-year' ? (
                <div className="flex items-center py-1 text-center bg-gray-300 rounded-lg">
                    <button
                        className="flex items-center justify-center w-10 h-6 p-1 rotate-180 disabled:opacity-20"
                        // disabled={
                        //     view === 'multi-year' &&
                        //     currentStartFilterIndex === 0
                        // }
                        onClick={() => handleStartDateFilterClick(-1)}
                    >
                        <CaretRightIcon className="w-4 h-4 caret-icon" />
                    </button>
                    <h3 className="flex-auto font-bold text-1xl">
                        {years[currentStartFilterIndex]}
                    </h3>
                    <button
                        className="flex items-center justify-center w-8 h-6 p-1 disabled:opacity-20"
                        disabled={
                            view === 'multi-year' &&
                            currentStartFilterIndex === currentEndFilterIndex
                        }
                        onClick={() => handleStartDateFilterClick(1)}
                    >
                        <CaretRightIcon className="w-4 h-4 caret-icon" />
                    </button>
                </div>
            ) : (
                <div className="flex items-center py-1 mt-1 text-center bg-gray-300 rounded-lg">
                    <button
                        className="flex items-center justify-center w-10 h-6 p-1 rotate-180 disabled:opacity-20"
                        disabled={
                            currentEndFilterIndex === currentStartFilterIndex
                        }
                        onClick={() => handleEndDateFilterClick(-1)}
                    >
                        <span className="text-1xl">&#8212;</span>
                    </button>
                    <h3 className="flex-auto font-bold text-1xl">
                        {years[currentStartFilterIndex]} -{' '}
                        {years[currentEndFilterIndex]}
                    </h3>
                    <button
                        className="flex items-center justify-center w-8 h-6 p-1 disabled:opacity-20"
                        disabled={currentEndFilterIndex === years.length - 1}
                        onClick={() => handleEndDateFilterClick(1)}
                    >
                        <span className="text-1xl">&#43;</span>
                    </button>
                </div>
            )}
        </div>
    )
}

export default YearlyFilters
