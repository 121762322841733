import './franchise-hub.scss'
import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { loadableNavAtom } from 'utils/atoms'
import bcgWaterTower from '../../__shared/images/waterTowerHero.jpg'
import WBLandingPageImg from '../../__shared/images/wb-landing-page.jpg'
import { ReactComponent as Caret } from '__shared/images/icons/caret.svg'
import FooterLinks from './components/FooterLinks/FooterLinks'

export default function FranchiseHub() {
    const [{ data: franchises }] = useAtom(loadableNavAtom)

    return (
        <main>
            {/* Hero section */}
            <section className="relative pb-16 overflow-hidden bg-gray-900 isolate pt-14">
                <img
                    src={bcgWaterTower}
                    alt="backgroundWBWaterTowerImage"
                    className="absolute inset-0 object-cover w-full h-full -z-10"
                />
                <div
                    className="absolute inset-x-0 top-0 bottom-0 overflow-hidden -z-10 transform-gpu "
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gradient-to-b from-[#000519] to-[#000519] opacity-40" />
                </div>
                <div className="px-6 mx-auto max-w-7xl lg:px-1">
                    <div className="max-w-4xl pt-32 pb-20 mx-auto sm:pt-36">
                        <div className="mt-5 text-center">
                            <h1 className="text-5xl font-extrabold tracking-wider text-white sm:text-6xl md:text-7xl font-openSans hero-title">
                                Franchise Hub
                            </h1>
                            {/* <p className="mt-6 text-lg font-bold leading-5 text-white uppercase font-openSans tracking-[0.5em]">
                                "The stuff that dreams are made of."
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <p className="text-3xl font-semibold text-center text-white font-openSans">
                            Explore our Brands
                        </p>
                        {/* Logo cloud */}
                        <div className="flex flex-wrap justify-center max-w-lg pt-8 mx-auto gap-y-4 sm:max-w-xl lg:max-w-2xl xl:max-w-6xl">
                            {franchises?.map((franchise) => {
                                if (franchise.explore_icon) {
                                    return (
                                        <Link
                                            to={franchise.slug}
                                            className="flex flex-col w-48 hover:opacity-80"
                                            key={franchise.id}
                                        >
                                            {franchise.explore_icon ? (
                                                <img
                                                    className="object-scale-down h-24 pb-2 mx-auto w-28"
                                                    src={franchise.explore_icon}
                                                    alt={franchise.name}
                                                ></img>
                                            ) : (
                                                ''
                                            )}
                                        </Link>
                                    )
                                }
                                return ''
                            })}
                        </div>
                    </div>
                </div>
            </section>

            {/* Welcome section */}
            <section className="mx-auto bg-white max-w-[1440px]">
                <div className="flex items-stretch mx-auto ">
                    <div className="flex-none hidden w-full md:block md:basis-7/12 welcome-section-bg gap-y-10">
                        <div className="relative h-full mx-0 aspect-auto">
                            <img
                                className="absolute inset-0 object-cover w-full h-full"
                                src={WBLandingPageImg}
                            ></img>
                        </div>
                    </div>
                    <div className="max-w-xl py-12 pl-6 pr-12 mx-auto text-center md:text-left md:basis-5/12">
                        <h1 className="text-4xl font-bold tracking-wide uppercase font-wbSans text-midblue pt-10">
                            Welcome to Franchise Hub
                        </h1>

                        <p className="pt-10 text-xl ">
                            The Franchise Hub is the one-stop, digital destination for Warner Bros. Discovery Brand and Franchise information
                        </p>
                        <p className="pt-10 text-xl ">
                            &nbsp;
                        </p>
                        <div className="inline-block mt-8">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </section>
            <FooterLinks franchises={franchises} />
        </main>
    )
}
