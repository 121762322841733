import SectionEditButton from '../SectionEditButton/SectionEditButton'
import './BrandFranchiseResources.scss'
const BrandFranchiseResources = ({ content }) => {
    return (
        <section
            className="relative mx-auto overflow-y isolate max-w-8xl"
            id="brand-resources"
        >
            <img
                src={content.structured.resources_image_left}
                alt=""
                className="absolute inset-0 object-cover w-full h-full bg-white -z-10"
            />
            <SectionEditButton hash="#section-resources" id={content.ID} />
            <div
                className="absolute inset-0 overflow-hidden -z-10 transform-gpu"
                aria-hidden="true"
            >
                <div
                    className={` relative w-full h-full bg- bg-gradient-to-b opacity-90`}
                    // TODO overview franchise color does not update
                    style={{
                        backgroundColor:
                            content.structured.resources_background_color,
                    }}
                />
            </div>
            <div className="">
                <div className="relative grid h-full max-h-content md:grid-cols-2">
                    <div className="py-10">
                        <div className="w-full pl-20 md:pl-14 lg:max-w-3xl resources-branded-font-color">
                            <h1 className="mb-8 text-5xl font-extrabold font-openSans wp-resources-title">
                                Resources
                            </h1>
                            <div
                                className=" wp-content wp-resources"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        content && content.structured.resources,
                                }}
                            />
                        </div>
                    </div>
                    {false && (
                        <div className="relative w-full h-full overflow-hidden md:block resources-img-container">
                            <img
                                src={content.structured.resources_image_right}
                                className="object-cover w-full h-full max-h-full resources-img"
                            ></img>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default BrandFranchiseResources
