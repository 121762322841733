import { useState, useEffect } from 'react'

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize())

    function getScreenSize() {
        const width = window.innerWidth
        if (width < 640) {
            return 'mobile'
        } else if (width < 768) {
            return 'tablet'
        } else if (width < 1024) {
            return 'lg-tablet'
        } else {
            return 'desktop'
        }
    }

    // update screen size when window is resized
    function handleResize() {
        const newSize = getScreenSize()
        console.log(
            '🚀 ~ file: useScreenSize.js:20 ~ handleResize ~ newSize:',
            newSize
        )
        setScreenSize(newSize)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return screenSize
}

export default useScreenSize
