import './home.scss'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import Features from 'pages/home/components/Features/Features'
import HeroCarousel from 'pages/home/components/HeroCarousel/HeroCarousel'

function Home() {
    return (
        <div
            className="w-full mx-auto homepage standard-page max-w-8xl "
            data-attr="test"
        >
            <header className="relative grid py-6 text-white px-18">
                <Nav />
                <HeroCarousel />
            </header>
            <Features />
            <Footer />
        </div>
    )
}

export default Home
