import { useState, useEffect } from 'react'
import brandBgImg from '__shared/images/brand-hero-bg.png'
import { Link } from 'react-router-dom'
import useApi from 'hooks/useApi'
import { useLocation, useParams } from 'react-router-dom'
import './ArticlePage.scss'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'
import { ReactComponent as CaretRightIcon } from '__shared/images/icons/caret.svg'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import RestrictedContent from 'components/RestrictedContent/RestrictedContent'
const ArticlePage = ({ guidelines }) => {
    const location = useLocation()
    const { brand, franchise, property, article } = useParams()
    const [guidelineCategories, setGuidelineCategories] = useState([])
    const {
        currentBrand,
        currentFranchises,
        currentFranchise,
        currentFranchiseArticles,
        currentSelection,
    } = useGetCurrentLevelNav()

    useEffect(() => {
        if (!currentFranchiseArticles) return
        setGuidelineCategories(
            Object.keys(currentFranchiseArticles).filter((category) =>
                category.toLowerCase().endsWith('guidelines')
            )
        )
    }, [currentFranchiseArticles])

    const navLinks = [
        {
            name: 'Overview',
            link: '#',
        },
        {
            name: 'Guidelines',
            link: '#',
        },
        {
            name: 'Franchises',
            link: '#',
        },
        {
            name: 'Resources',
            link: '#',
        },
    ]

    const {
        responseJSON: content,
        isLoading,
        restrictedContentError,
    } = useApi(
        `page?slug=${encodeURIComponent(
            location.pathname.replace('/franchise/', '')
        )}&${location.search.replace('?', '')}`,
        'get'
    )

    return (
        <div className="flex flex-grow bg-wbslate article-page ">
            <section className="relative bg-gray-900 overflow-y isolate pt-20 max-w-[1440px] mx-auto flex flex-grow">
                <img
                    src={brandBgImg}
                    alt=""
                    className="absolute inset-0 object-cover w-full h-full max-h-screen -z-10"
                />
                <div
                    className="absolute inset-x-0 bottom-0 overflow-hidden -z-10 transform-gpu blur-3xl"
                    aria-hidden="true"
                >
                    <div className="relative aspect-square bg-gradient-to-b from-[#000519] to-[#000519] opacity-60" />
                </div>

                <main className="relative z-10 flex flex-col flex-grow pb-0 mx-auto mt-12">
                    <div className="flex flex-col items-center mx-auto gap-y-8 max-w-8xl ">
                        <div className="w-full max-w-2xl mx-auto text-center lg:max-w-none lg:flex-auto ">
                            <h1 className="mb-6 text-5xl font-extrabold tracking-wide text-white sm:text-6xl lg:text-7xl font-openSans">
                                {property || franchise || brand}{' '}
                                {property
                                    ? 'Property'
                                    : franchise
                                    ? 'Franchise'
                                    : 'Brand'}
                            </h1>
                            {/* <h2 className="text-xl font-bold tracking-[.5em] text-white mt-7 font-openSans hidden">
                                    Earth-2's Mightiest Heroes
                                </h2> */}
                            {/* <div className="hidden px-4 py-4 mx-auto my-12 bg-black w-fit">
                                    <ul className="flex items-center justify-center divide-x-2 ">
                                        {navLinks.map(({ name, link }) => (
                                            <Link
                                                to={link}
                                                className="px-4 text-3xl font-bold uppercase"
                                                key={name}
                                            >
                                                {name}
                                            </Link>
                                        ))}
                                    </ul>
                                </div> */}
                        </div>
                    </div>
                    {isLoading ? null : restrictedContentError ? (
                        <RestrictedContent />
                    ) : (
                        <div className="w-full pt-4 mx-auto bg-white article-page max-w-8xl min-h-franchise-article">
                            <Breadcrumbs />
                            <hr className="mt-4" />
                            {guidelines ? (
                                guidelineCategories && (
                                    <div className="mx-auto max-w-8xl">
                                        <section className="pt-12">
                                            <div className="px-12 border-b-2">
                                                <h1 className="mt-3 mb-5 text-5xl font-extrabold tracking-tight text-midblue font-openSans">
                                                    Guidelines
                                                </h1>
                                                <div className="py-12 lg:px-16 w-fit ">
                                                    <ul className="flex flex-col divide-y-2">
                                                        {guidelineCategories.map(
                                                            (guideline) => {
                                                                return (
                                                                    <li
                                                                        className="flex items-center hover:opacity-80 overall-guidelines-items"
                                                                        key={
                                                                            guideline
                                                                        }
                                                                    >
                                                                        <CaretRightIcon className="w-3 h-6 mr-6 caret-icon caret-icon-right" />

                                                                        <a
                                                                            className="text-3xl font-extrabold tracking-wide uppercase text-lightroyalblue font-openSans"
                                                                            href={`#${guideline}`}
                                                                        >
                                                                            {' '}
                                                                            {
                                                                                guideline
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                        {guidelineCategories.map(
                                            (guideline) => {
                                                return (
                                                    <section
                                                        className="pt-12"
                                                        id={guideline}
                                                    >
                                                        <div className="px-12 border-b-2">
                                                            <h1 className="mt-3 mb-5 text-3xl font-extrabold tracking-tight text-midblue font-openSans">
                                                                {guideline}
                                                            </h1>
                                                            <div className="pb-12 w-fit">
                                                                <ul className="flex flex-col list-disc list-inside guideline-list ">
                                                                    {currentFranchiseArticles[
                                                                        guideline
                                                                    ].map(
                                                                        (
                                                                            guideline
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        guideline.id
                                                                                    }
                                                                                    className="relative flex items-center hover:opacity-80 bullets"
                                                                                >
                                                                                    <Link
                                                                                        className="pl-8 text-2xl font-bold tracking-wide capitalize text-lightroyalblue font-openSans"
                                                                                        to={`${guideline.slug}`}
                                                                                    >
                                                                                        {
                                                                                            guideline.title
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            }
                                        )}
                                    </div>
                                )
                            ) : (
                                <div className="px-12 py-12 wp-content">
                                    <h1 className=" text-midblue">
                                        {content?.post_title}
                                    </h1>

                                    <div
                                        className="mx-auto wp-content max-w-8xl"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                content && content?.post_content
                                                    ? content?.post_content
                                                    : '<h1>Coming Soon</h1>',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </main>
            </section>
        </div>
    )
}

export default ArticlePage
