import { useState, useRef, memo } from 'react'
import './FranchiseTopbar.scss'
import FranchiseNav from '../FranchiseNav/FranchiseNav'
import { matchesAtom, searchTermAtom, isNavHoveredAtom } from 'utils/atoms'
import { ReactComponent as ExternalIcon } from '__shared/images/icons/Security.svg'
import { ReactComponent as InternalIcon } from '__shared/images/icons/Security-Internal.svg'
import SeekerLogo from '__shared/images/icons/Seeker-Logo.png'
import AnimatedSearch from '../AnimatedSearch/AnimatedSearch'
import { Dialog } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import NavPopover from '../NavPopover/NavPopover'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import { useSetAtom } from 'jotai'
import { Link } from 'react-router-dom'

export default memo(function FranchiseTopbar({ calendar }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isInternal, setIsInternal] = useState(true)
    const setIsNavHovered = useSetAtom(isNavHoveredAtom)
    const setMatches = useSetAtom(matchesAtom)
    const setSearchTerm = useSetAtom(searchTermAtom)
    const searchInputRef = useRef(null)

    const onNavHover = (e) => {
        if (!e.target.closest('.franchiseNav-buttons')) {
            setIsPopoverOpen(true)
        } else {
            setIsPopoverOpen(false)
        }

        setIsNavHovered(true)
    }
    const onNavLeave = () => {
        setIsPopoverOpen(false)
        setIsNavHovered(false)
        setMatches([])
        setSearchTerm('')
        searchInputRef.current.blur()
    }

    const handlePopoverOpen = () => {
        setIsPopoverOpen(true)
    }

    const handlePopoverClose = () => {
        setIsPopoverOpen(false)
    }
    return (
        <header className="absolute inset-x-0 top-0 z-50 franchise-topbarv2 group">
            <nav
                className="relative flex justify-center w-full px-10 nav"
                aria-label="Global"
                onMouseOver={onNavHover}
                onMouseLeave={onNavLeave}
            >
                <NavPopover
                    handlePopoverClose={handlePopoverClose}
                    isPopoverOpen={isPopoverOpen}
                />
                <div className="flex items-center justify-between w-full h-[97px] max-w-8xl nav-center">
                    {/* <div className="flex items-center sm:hidden">
                        <Link to="/franchise" className="-m-1.5 p-1.5">
                            <span className="sr-only">Seeker</span>
                            <img
                                className="w-auto h-13 seeker-logo"
                                src={SeekerLogo}
                                alt="Seeker Logo"
                            />
                        </Link>
                    </div> */}

                    <div
                        onClick={() => {
                            handlePopoverClose()
                        }}
                        className="flex items-center text-sm font-semibold leading-6 text-black gap-x-1"
                    >
                        <div className="flex items-center lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Seeker</span>
                                <img
                                    className="w-auto h-13 seeker-logo"
                                    src={SeekerLogo}
                                    alt="Seeker Logo"
                                />
                            </a>
                        </div>
                    </div>
                    {/* Hamburger mobile menu */}
                    <div className="flex sm:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex rounded-md p-2.5 text-white "
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon
                                className="flex w-10 h-10 hamburger-nav"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div className="items-center self-stretch hidden sm:flex sm:justify-end franchiseNav-buttons gap-x-4">
                        {/* <a
                            href="https://airtable.com/shrArcqv6NTaC8NHg"
                            target="_blank"
                            className="px-2 py-2 text-black bg-white rounded-md group-hover:invert"
                        >
                            Feedback & Questions
                        </a> */}
                        <AnimatedSearch ref={searchInputRef} />
                        <ProfileDropdown />
                        <div
                            className="hidden"
                            onClick={() => setIsInternal(!isInternal)}
                        >
                            {isInternal ? (
                                <InternalIcon className="internal-icon" />
                            ) : (
                                <ExternalIcon className="external-icon" />
                            )}
                        </div>
                    </div>
                    {/* <hr className="absolute w-full z-100 bg-blue text-blue" /> */}
                </div>
                {/* <div className="flex-none w-px w-full bg-blue"></div> */}
            </nav>

            {/* Modal panel for mobile nav */}
            <Dialog
                as="div"
                className="sm:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black">
                    <FranchiseNav
                        setMobileMenuOpen={setMobileMenuOpen}
                        calendar={calendar}
                    />
                </Dialog.Panel>
            </Dialog>
        </header>
    )
})
