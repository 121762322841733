import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import FranchisePropertyCards from './FranchisePropertyCards/FranchisePropertyCards'
import useGetCurrentLevelNav from 'hooks/useGetCurrentLevelNav'
import useScreenSize from 'hooks/useScreenSize'
import './ExploreOurBrandsFranchises.scss'

const ExploreOurBrandsFranchises = ({ content }) => {
    const { brand, franchise } = useParams()
    const [showAll, setShowAll] = useState(false)
    // const [displayedItems, setDisplayedItems] = useState([])
    const [linkCards, setLinkCards] = useState([])
    const { currentSelection } = useGetCurrentLevelNav()
    const screenSize = useScreenSize()
    const cardsPerRow =
        screenSize === 'desktop'
            ? 5
            : screenSize === 'tablet'
            ? 6
            : screenSize === 'mobile'
            ? 4
            : 4

    useEffect(() => {
        if (!currentSelection) return
        const cards = currentSelection.children.filter(
            (pages) => pages.type === (franchise ? 'property' : 'franchise')
        )
        setLinkCards(cards)
    }, [currentSelection])

    const showMoreHandler = () => {
        console.log(linkCards)
        setShowAll(!showAll)
    }
    if (linkCards.length === 0) return null

    const renderedButton =
        linkCards.length > cardsPerRow ? (
            <button
                onClick={showMoreHandler}
                className=" sm:text-1xl uppercase rounded-[50px] bg-white text-fadedblue py-4.5 px-20 md:px-[150px] border-2 border-fadedblue mx-auto block opacity-70 font-bold tracking-widest font-openSans hover:opacity-80 focus:opacity-80"
            >
                {showAll
                    ? `See fewer ${
                          franchise
                              ? franchise.replaceAll('-', ' ')
                              : brand.replaceAll('-', ' ')
                      } ${franchise ? 'properties' : 'franchises'}`
                    : `See all  ${
                          franchise
                              ? franchise.replaceAll('-', ' ')
                              : brand.replaceAll('-', ' ')
                      } ${franchise ? 'properties' : 'franchises'}`}
            </button>
        ) : null

    return (
        <section
            id="brand-franchises"
            className="relative py-12 mx-auto bg-wbslate md:py-20 overflow-y isolate max-w-8xl"
        >
            {content.structured.view_all_background && (
                <img
                    src={content.structured.view_all_background}
                    alt="explore all background image"
                    className="absolute inset-0 object-cover w-full h-full -z-10"
                />
            )}
            <div
                className="absolute inset-0 overflow-hidden -z-10 transform-gpu"
                aria-hidden="true"
            >
                <div className="relative w-full h-full bg-gradient-to-b from-[#000519] to-[#000519] opacity-60" />
            </div>
            <div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 explore-branded-font-color">
                <h1 className="text-5xl font-extrabold text-center text-white font-openSans">
                    Explore our {franchise ? 'Properties' : 'Franchises'}
                </h1>
                <div className="mx-auto my-12 max-w-7xl">
                    <div
                        className={`${
                            showAll && ''
                        } grid grid-cols-2 mt-6 gap-x-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:gap-x-8 transitioner`}
                    >
                        {linkCards.map((franchise, index) => (
                            <FranchisePropertyCards
                                key={franchise.id}
                                franchise={franchise}
                                setShowAll={setShowAll}
                                showAll={showAll}
                                isVisible={showAll || index < cardsPerRow}
                                additionalCards={index >= cardsPerRow}
                                isErrorImg={!franchise.featured_image}
                            />
                        ))}
                    </div>
                </div>
                {renderedButton}
            </div>
        </section>
    )
}

export default ExploreOurBrandsFranchises
