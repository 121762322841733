import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Close } from '__shared/images/icons/close.svg'
import { ReactComponent as Caret } from '__shared/images/icons/caret.svg'
import Search from '../Search/Search'
import './FranchiseNav.scss'
import useCompileNav from 'hooks/useCompileNav'
export default memo(function FranchiseNav({ setMobileMenuOpen, calendar }) {
    const {
        navLabel,
        setNavLabel,
        navIcon,
        setNavIcon,
        navPlacement,
        setNavPlacement,
        selectedMenus,
        selection,
        navItems,
        setSelectedMenus,
    } = useCompileNav()
    const aLocation = useLocation()

    const drillIn = (v, level) => {
        setNavPlacement({
            ...navPlacement,
            [level]: v.id,
            level: navPlacement.level + 1,
        })
    }

    const drillBack = () => {
        if (navPlacement.level === 1) return
        setNavPlacement({
            ...navPlacement,
            level: navPlacement.level - 1,
        })
    }

    const toggleMenu = (i) => {
        const _selectedMenus = [...selectedMenus]
        if (!_selectedMenus.includes(i)) {
            _selectedMenus.push(i)
        } else {
            _selectedMenus.splice(_selectedMenus.indexOf(i), 1)
        }

        setSelectedMenus(_selectedMenus)
    }

    const item = (i, level) => {
        return (
            <li key={i.id}>
                <div>
                    <Link
                        to={calendar ? `${i.slug}` : i.slug}
                        data-content-id={i.id}
                        onClick={() => {
                            setNavIcon(i.icon)
                            setNavLabel(i.title)
                        }}
                        className="text-base font-extrabold leading-5 tracking-widest text-white uppercase font-openSans"
                    >
                        {i.title}
                    </Link>
                    {i.children && i.children.length > 0 ? (
                        <span
                            href="#"
                            className="drill-in pull-right pointer "
                            onClick={() => drillIn(i, `l${level}`)}
                        >
                            <Caret className="w-2 h-4" />
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            </li>
        )
    }

    return (
        <div className="h-full overflow-x-hidden text-white global-nav franchise-hub-globalnav">
            <div className="flex items-center px-6 py-8 nav-header">
                {navPlacement?.level > 1 ? (
                    <div
                        className="rotate-180 cursor-pointer back"
                        onClick={() => drillBack()}
                    >
                        <Caret className="w-2 h-4 ml-auto" />
                    </div>
                ) : (
                    <div></div>
                )}
                <div>
                    <div>
                        <div></div>
                    </div>
                    {navIcon ? (
                        <img src={navIcon} alt={`${navLabel} Logo`} />
                    ) : (
                        <></>
                    )}
                    <span className="text-base font-extrabold leading-5 tracking-widest text-white font-openSans">
                        {navLabel}
                    </span>
                </div>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setMobileMenuOpen(false)
                    }}
                >
                    <Close className="ml-auto" />
                </div>
            </div>

            {/* Search input */}
            <Search className="relative m-6" />

            <div className="nav-container">
                <nav
                    className={`franchise-hub-nav level-${navPlacement?.level} pb-10`}
                >
                    {[...Array(2)].map((j, k) => {
                        return (
                            <ul
                                key={k}
                                data-level={k}
                                className={`level-${k} ${
                                    navPlacement?.level === k + 1
                                        ? 'level-active'
                                        : ''
                                }`}
                            >
                                {navItems[k] ? (
                                    navItems[k]
                                        .sort((a, b) =>
                                            a.title.localeCompare(b.title)
                                        )
                                        .map((navItem, l) => {
                                            return item(navItem, k, l)
                                        })
                                ) : (
                                    <></>
                                )}
                            </ul>
                        )
                    })}
                    {/* {navPlacement?.level === 3 ? ( */}
                    <ul
                        data-level="2"
                        className={`level-2 ${
                            navPlacement?.level === 3 ? 'level-active' : ''
                        }`}
                    >
                        {selection &&
                            selection.nav &&
                            Object.keys(selection.nav).map((i) => {
                                {
                                    /* convert any object values to arrays */
                                }
                                if (!Array.isArray(selection.nav[i])) {
                                    selection.nav[i] = Object.values(
                                        selection.nav[i]
                                    )
                                }
                                return ['Unassigned', ''].indexOf(i) > -1 ? (
                                    <></>
                                ) : (
                                    <React.Fragment key={i}>
                                        <li
                                            onClick={() => toggleMenu(i)}
                                            className={`pointer content-type ${
                                                selectedMenus.indexOf(i) > -1
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                        >
                                            <span className="text-base font-extrabold leading-5 tracking-widest text-white uppercase font-openSans">
                                                {i}
                                            </span>
                                            <Caret className="w-2 h-4" />
                                        </li>
                                        <ul
                                            id={i}
                                            className={selectedMenus.join(', ')}
                                        >
                                            {selection.nav[i].map((id) => {
                                                let updatedId =
                                                    typeof id === 'object'
                                                        ? id.id
                                                        : id

                                                const item =
                                                    selection.children.find(
                                                        (ni) =>
                                                            ni.id ===
                                                            parseInt(updatedId)
                                                    )

                                                if (typeof item != 'object') {
                                                    return
                                                }

                                                const activeClass =
                                                    `${item.slug}` ==
                                                    aLocation.pathname
                                                        ? 'active'
                                                        : 'not-active '
                                                return (
                                                    <li key={updatedId}>
                                                        <Link
                                                            to={
                                                                calendar
                                                                    ? `${item.slug}`
                                                                    : item.slug
                                                            }
                                                            data-content-id={
                                                                item.id
                                                            }
                                                            className={`${activeClass}`}
                                                        >
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </React.Fragment>
                                )
                            })}
                    </ul>
                    {/* ) : (
                        <></>
                    )} */}
                </nav>
            </div>

            <div className="mx-4 my-6 text-base font-extrabold leading-5 tracking-widest text-white back-to-home font-openSans">
                <a href="/">
                    <Caret
                        className="w-2 h-4"
                        style={{ display: 'none !important' }}
                    />{' '}
                    Back to Seeker
                </a>
            </div>
        </div>
    )
})
