import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as PencilIcon } from '__shared/images/icons/pencil.svg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
const Popup = ({ selectedEvent, onClose, setEvents, events }) => {
    const [open, setOpen] = useState(true)
    const closeButtonRef = useRef(null)
    const [isEventDisplayed, setIsEventDisplayed] = useState(
        selectedEvent.isVisible
    )
    // focus on close button on page load
    useEffect(() => {
        closeButtonRef.current.focus()
    }, [])

    const getFormattedDate = (date) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }
        return new Date(date).toLocaleString('en-US', options)
    }

    const toggleEventVisibility = ({ ID }) => {
        setIsEventDisplayed((isEventDisplayed) => !isEventDisplayed)

        setEvents((prevEvents) =>
            prevEvents.map((event) =>
                event.ID === ID
                    ? { ...event, isVisible: !event.isVisible }
                    : event
            )
        )
    }

    const toggleVisibilityButton = (
        <div className="absolute z-10 right-12 pointer">
            <button
                className="hover:opacity-80"
                title={isEventDisplayed ? 'Hide Event' : 'Unhide Event'}
                onClick={() => toggleEventVisibility(selectedEvent)}
            >
                {isEventDisplayed ? (
                    <FontAwesomeIcon
                        icon={faEye}
                        className="text-1xl text-blue"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="text-1xl text-wbgray "
                    />
                )}
            </button>
        </div>
    )

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-70" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative px-10 py-10 overflow-hidden text-left transition-all transform bg-white rounded-[1.25rem] shadow-xl sm:my-8 sm:w-full sm:max-w-3xl sm:py-10 sm:px-12">
                                {toggleVisibilityButton}
                                <div
                                    className={`${
                                        selectedEvent.image[0] ? 'divide-x' : ''
                                    } relative flex justify-center gap-4  divide-gray-500 align-items`}
                                >
                                    <div
                                        className={`${
                                            selectedEvent.image[0]
                                                ? 'w-1/3'
                                                : 'hidden'
                                        } sm:mt-5`}
                                    >
                                        <div className="h-32 ">
                                            <img
                                                src={selectedEvent.image[0]}
                                                alt=""
                                                className="object-contain w-full h-full"
                                            />
                                        </div>
                                        <div className="mx-auto text-center ">
                                            {selectedEvent?.metadata
                                                ?.format && (
                                                <p className=" max-w-full mx-auto px-4 py-2 tracking-wider text-white uppercase bg-[#00B894] rounded w-max">
                                                    {
                                                        selectedEvent?.metadata
                                                            ?.format
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`${
                                            selectedEvent.image[0]
                                                ? 'w-2/3'
                                                : 'w-full'
                                        } grid pl-4 sm:mt-5`}
                                    >
                                        <Dialog.Title
                                            as="h3"
                                            className="text-3xl font-extrabold tracking-normal uppercase text-midblue font-openSans"
                                        >
                                            {selectedEvent.post_title}
                                        </Dialog.Title>
                                        <div className="mt-2 text-xl font-bold tracking-wider uppercase text-blue">
                                            {getFormattedDate(
                                                selectedEvent.metadata
                                                    .start_date
                                            )}
                                        </div>
                                        <div className="mt-2">
                                            <Dialog.Description className="text-xl font-bold text-gray-500">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedEvent.post_content,
                                                    }}
                                                />
                                            </Dialog.Description>
                                        </div>
                                        <div className="items-end mt-10 justify-self-end">
                                            <Link
                                                target="_blank"
                                                rel="noreferrer"
                                                to={`https://${
                                                    window.location.host !==
                                                    'seeker.franchise.warnerbros.com'
                                                        ? 'stage.'
                                                        : ''
                                                }cms.franchise.warnerbros.com/wp-admin/post.php?post=${
                                                    selectedEvent?.ID
                                                }&action=edit`}
                                                className="flex items-center h-8 text-xl gap-x-3 text-blue hover:opacity-75"
                                            >
                                                <PencilIcon className="w-6 h-6" />
                                                Edit Event
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-6">
                                    <button
                                        ref={closeButtonRef}
                                        type="button"
                                        className="block mx-auto px-32 py-3.5 text-1xl font-semibold font-openSans text-white uppercase rounded-[3.125rem] shadow-sm bg-blue hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue leading-6 tracking-[0.2em]"
                                        onClick={() => {
                                            setOpen(false)
                                            onClose()
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Popup
