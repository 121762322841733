import React from 'react'
import { features } from '../../../../utils/constants'
import './Features.scss'

const Features = () => {
    return (
        <main>
            <section
                id="features"
                className="px-3 py-12 text-center bg-darkerblue"
            >
                <div className="relative max-w-3xl mx-auto mb-12 features-header">
                    <h2 className="text-8xl ">What is Seeker?</h2>
                    <p className="my-4 text-base font-bold leading-6 tracking-wide">
                        Seeker is the newly branded Warner Bros. Consumer
                        Products (WBCP) collaboration portal that will make it
                        easier to do business with WBCP. It will cover many
                        business processes, including:{' '}
                    </p>
                </div>
                <div className="features">
                    {features.map(
                        (
                            { icon, headline, subheadline, description },
                            index
                        ) => {
                            return (
                                <figure key={index} className="mx-auto mb-6">
                                    <div>{icon}</div>
                                    <h5 className="mt-6 text-2xl">
                                        {headline}
                                    </h5>
                                    <p className="my-2.5 text-sm">
                                        {subheadline}
                                    </p>
                                    <figcaption className="text-sm">
                                        {description}
                                    </figcaption>
                                </figure>
                            )
                        }
                    )}
                </div>
            </section>
        </main>
    )
}

export default Features
