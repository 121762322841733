import { useState, useEffect } from 'react'
import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'
import './releaseNotesPage.scss'
import ReleaseNote from './components/ReleaseNote'
import useApi from 'hooks/useApi'
export default function ReleaseNotes() {
    const {
        responseJSON: releaseNotes,
        isLoading,
        error,
    } = useApi('posts?categories=release-notes', 'get')

    return (
        <div className="mx-auto standard-page max-w-8xl">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>
            <main className="mx-10 text-black py-7 release-notes min-h-page">
                <div className="px-4 py-10 overflow-y-scroll bg-white font-wbs scroll-container rounded-2xl ">
                    <h1 className="mt-0 mb-10 text-4xl font-semibold leading-4 uppercase font-wbs text-blue">
                        Release Notes
                    </h1>
                    {releaseNotes?.map((releaseNote, index) => {
                        return (
                            <ReleaseNote
                                releaseNote={releaseNote}
                                index={index}
                            />
                        )
                    })}
                </div>
            </main>
            <Footer />
        </div>
    )
}
