import { Link, useParams } from 'react-router-dom'
import SectionEditButton from '../SectionEditButton/SectionEditButton'
import './BrandFranchiseGuidelines.scss'
const BrandFranchiseGuidelines = ({ content }) => {
    const { brand, franchise, property } = useParams()
    return (
        <section
            className="relative py-20 mx-auto isolate max-w-8xl"
            id="brand-guidelines"
        >
            <div className="absolute inset-0 -z-10">
                <img
                    src={content.structured.guidelines_background}
                    alt=""
                    className="object-cover w-full h-full "
                />
            </div>
            <SectionEditButton hash="#section-guidelines" id={content.ID} />
            {!content.structured.guidelines_background && (
                <div
                    className="absolute inset-0 overflow-hidden -z-10 transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        style={{
                            backgroundColor:
                                content?.structured
                                    ?.guidelines_background_color,
                        }}
                        className="relative w-full h-full opacity-90"
                    />
                </div>
            )}
            <div className="px-12 lg:px-20">
                <div className="relative flex flex-col max-w-2xl gap-16 mx-auto lg:mx-0 lg:max-w-none lg:flex-row ">
                    <div className="relative  min-w-[400px] basis-1/3">
                        <div className="absolute w-full h-full lg:flex-auto">
                            {content.structured.guidelines_image && (
                                <img
                                    src={content.structured.guidelines_image}
                                    alt="brand image"
                                    className="object-contain w-full h-full"
                                />
                            )}
                        </div>
                    </div>
                    <div className="w-full ml-auto lg:max-w-3xl basis-2/3 guidelines-branded-font-color">
                        <h1 className="mb-8 text-5xl font-extrabold text-white font-openSans wp-resources-title">
                            {property || franchise || brand} Guidelines
                        </h1>
                        <div
                            className="wp-guidelines wp-content"
                            dangerouslySetInnerHTML={{
                                __html:
                                    content && content.structured.guidelines,
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandFranchiseGuidelines
