import { useEffect, forwardRef } from 'react'
import { flat } from 'utils/utils'
import { DebounceInput } from 'react-debounce-input'
import { useAtom, useAtomValue } from 'jotai'
import { navAtom, matchesAtom, searchTermAtom } from 'utils/atoms'
import { Link } from 'react-router-dom'
import './AnimatedSearch.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

const AnimatedSearch = forwardRef(function (props, ref) {
    // const [term, setTerm] = useState('')
    // const [matches, setMatches] = useState([])
    const [matches, setMatches] = useAtom(matchesAtom)
    const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)

    const nav = useAtomValue(navAtom)

    useEffect(() => {
        if (searchTerm.length < 3) {
            setMatches([])
            return
        }

        const searchableNav = flat(nav)
        const matched = searchableNav.filter((i) => {
            return i.title.toLowerCase().indexOf(searchTerm) > -1
        })

        setMatches(matched)
    }, [searchTerm, nav])

    return (
        <div className="animatedsearch-container franchiseNav-buttons">
            <form
                className="relative flex items-center justify-end mx-auto w-max"
                onSubmit={(e) => e.preventDefault()}
            >
                <DebounceInput
                    inputRef={ref}
                    minLength={3}
                    onKeyDown={(event) => setSearchTerm(event.target.value)}
                    debounceTimeout={500}
                    className="relative z-10 w-12 h-10 pr-12 bg-transparent rounded-full outline-none cursor-pointer peer focus:cursor-text focus:w-full focus:border-black focus:border focus:pl-12 search-input"
                />
                <button
                    title="Search"
                    className="absolute flex my-auto ml-2 text-3xl search-icon peer-focus:left-1"
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </form>
            <div className="matches">
                {matches.map((match) => {
                    return (
                        <div>
                            <Link
                                to={match.slug}
                                onClick={() => setSearchTerm('')}
                                className="text-xs"
                            >
                                {match.title}
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})
export default AnimatedSearch
