import React from 'react'
import './BrandFranchiseHero.scss'
import SectionEditButton from '../SectionEditButton/SectionEditButton'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

const BrandFranchiseHero = ({
    content,
    franchise,
    property,
    contentIsLoading,
}) => {
    return (
        <section className="relative bg-gray-900 overflow-y isolate pt-24 max-w-[1440px] mx-auto brand-hero">
            {content.structured.introduction_background && (
                <img
                    src={content.structured.introduction_background}
                    alt=""
                    className="absolute inset-0 object-cover w-full h-full -z-10"
                />
            )}

            <SectionEditButton
                hash="#section-introduction"
                id={content.ID}
                className="mr-10 top-20"
            />

            <div
                className="absolute inset-0 bottom-0 overflow-hidden bg-gradient-to-b opacity-60 -z-10 transform-gpu blur-3xl"
                style={{
                    backgroundColor:
                        content.structured.introduction_background_color,
                }}
            />
            <Breadcrumbs />
            <div className="relative z-10 pb-0 mt-28 lg:mt-0 md:pb-24 lg:pb-0 min-h-[768px]">
                {!contentIsLoading && (
                    <div className="flex flex-col items-center lg:pr-12 mx-auto max-w-[1440px] gap-y-10 gap-x-4 sm:gap-y-10 lg:flex-row lg:items-stretch ">
                        <div className="w-full max-w-3xl lg:w-7/12 lg:flex-none ">
                            {content.structured.introduction_image && (
                                <div className="relative flex items-center justify-center h-full lg:mx-0 lg:aspect-auto">
                                    <img
                                        className="absolute object-contain max-w-full max-h-full lg:object-contain"
                                        src={
                                            content.structured
                                                .introduction_image
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>
                        <div className="w-full max-w-2xl lg:max-w-none lg:px-4 lg:py-9">
                            <figure className="relative px-6 pt-2 isolate md:px-0 hero-branded-font-color">
                                {content.structured.icon ? (
                                    <img
                                        src={content.structured.icon}
                                        alt=""
                                        className="max-h-[200px] max-w-[500px]  mx-auto"
                                    />
                                ) : (
                                    <h1 className="mt-6 font-extrabold text-center text-white uppercase font-openSans">
                                        {!franchise && 'Welcome to'}{' '}
                                        {content.post_title}{' '}
                                        {franchise && !property && 'Franchise'}
                                    </h1>
                                )}
                                <div
                                    className="pt-6 text-white wp-content wp-brand-hero"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            content &&
                                            content.structured.introduction,
                                    }}
                                />
                                {/* <button className="text-lg block py-5 mx-auto mt-12 mb-4 font-bold leading-6 tracking-widest text-white uppercase duration-100 ease-linear xl:mb-32 opacity-80 px-36 lg:px-28 xl:px-36 bg-blue rounded-[50px] hover:opacity-100 focus:opacity-100 font-openSans">
                                    Read more
                                </button> */}
                            </figure>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default BrandFranchiseHero
