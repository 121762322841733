import React, { useState, useEffect } from 'react'
import Loading from 'components/Loading/Loading.js'
import YearlyFilters from './YearlyFilters/YearlyFilters.js'
import WeeklyFilter from './WeeklyFilter/WeeklyFilter.js'
import YearSelectInputs from './SelectYearFilters/YearSelectInputs.js'
import TableRow from './TableRow/TableRow.js'

const Calendar = ({
    view,
    headers,
    setHeaders,
    years,
    events,
    setFilter,
    previousWeek,
    nextWeek,
    isEventsLoading,
    sortedCategoryTypes,
    categoryTypes,
    fCategories,
    sortedFCategories,
    setSelectedEvent,
    themes,
}) => {
    const [currentStartFilterIndex, setCurrentStartFilterIndex] = useState(0)
    const [currentEndFilterIndex, setCurrentEndFilterIndex] = useState(1)

    // Reset filter values on page view change
    useEffect(() => {
        if (years.length === 0 || view === 'agenda') return
        if (view === 'week') {
            // setFilter((prev) => {
            //     return {
            //         ...prev,
            //     }
            // })
        } else {
            setFilter((prev) => {
                return {
                    ...prev,
                    start: years[currentStartFilterIndex],
                    end: view === 'multi-year' && years[currentEndFilterIndex],
                }
            })
            setCurrentStartFilterIndex(0)
            setCurrentEndFilterIndex(1)
        }
    }, [view, years])

    useEffect(() => {
        if (view !== 'multi-year') return
        setHeaders(
            years.slice(currentStartFilterIndex, currentEndFilterIndex + 1)
        )
    }, [currentStartFilterIndex, currentEndFilterIndex, view, years])

    const handleStartDateFilterClick = (increment) => {
        let tempIndex = currentStartFilterIndex + increment
        if (
            tempIndex === years.length ||
            tempIndex < 0 ||
            (view === 'multi-year' && tempIndex > currentEndFilterIndex)
        )
            return
        setCurrentStartFilterIndex(tempIndex)
        setFilter((prev) => {
            return {
                ...prev,
                type: 'year',
                start: years[tempIndex],
            }
        })
    }
    const handleEndDateFilterClick = (increment) => {
        let tempIndex = currentEndFilterIndex + increment
        if (tempIndex < currentStartFilterIndex) return
        setCurrentEndFilterIndex(tempIndex)
        setFilter((prev) => {
            return {
                ...prev,
                type: 'year',
                end: years[tempIndex],
            }
        })
    }

    const renderTableBody = () => {
        return sortedCategoryTypes.map((event_category, i) => (
            <TableRow
                headers={headers}
                event_category={event_category}
                categoryTypes={categoryTypes}
                fCategories={fCategories}
                sortedFCategories={sortedFCategories}
                setSelectedEvent={setSelectedEvent}
                themes={themes}
                key={i}
            />
        ))
    }

    return (
        <div className="w-full px-10 pb-10 overflow-x-auto bg-white border-collapse calendar-container rounded-br-xl rounded-bl-xl min-h-page">
            {events.length < 1 && isEventsLoading ? (
                <div className="mx-auto px-18 max-w-8xl">
                    <Loading />
                </div>
            ) : (
                <>
                    {view === 'multi-year' && (
                        <YearSelectInputs
                            years={years}
                            currentEndFilterIndex={currentEndFilterIndex}
                            currentStartFilterIndex={currentStartFilterIndex}
                            setCurrentEndFilterIndex={setCurrentEndFilterIndex}
                            setCurrentStartFilterIndex={
                                setCurrentStartFilterIndex
                            }
                            setFilter={setFilter}
                        />
                    )}

                    <table
                        id="calendar"
                        className={`${
                            events.length < 1 && !isEventsLoading ? '' : ''
                        } w-full min-w-[1280px] overflow-x-auto bg-white table-fixed`}
                    >
                        <thead>
                            <tr>
                                <th className="w-48 pb-2 pr-2">
                                    {view === 'week' ? (
                                        <WeeklyFilter
                                            previousWeek={previousWeek}
                                            nextWeek={nextWeek}
                                            years={years}
                                            view={view}
                                            headers={headers}
                                        />
                                    ) : (
                                        <YearlyFilters
                                            view={view}
                                            currentStartFilterIndex={
                                                currentStartFilterIndex
                                            }
                                            currentEndFilterIndex={
                                                currentEndFilterIndex
                                            }
                                            years={years}
                                            handleStartDateFilterClick={
                                                handleStartDateFilterClick
                                            }
                                            handleEndDateFilterClick={
                                                handleEndDateFilterClick
                                            }
                                        />
                                    )}
                                </th>
                                {headers?.map((header, i) => {
                                    return (
                                        <th
                                            className="pb-2 text-2xl uppercase font-wbSans tracking-[.2em] text-lightgray border-b-2 "
                                            key={i}
                                        >
                                            <div>
                                                {view === 'multi-year' ||
                                                view === 'week'
                                                    ? header
                                                    : Intl.DateTimeFormat(
                                                          'en',
                                                          {
                                                              month: 'short',
                                                          }
                                                      ).format(
                                                          new Date(header)
                                                      )}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {events.length < 1 && !isEventsLoading ? (
                                <tr>
                                    <th className="border-t-2 border-r-0 border-gray200">
                                        <h4>No events available...</h4>
                                    </th>
                                </tr>
                            ) : (
                                renderTableBody()
                            )}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    )
}

export default Calendar
