import { useState, useEffect } from 'react'

function useLocalStorage(key, initialValue) {
    // Write your code here.
    const [value, setValue] = useState(
        () => JSON.parse(window.localStorage.getItem(key)) ?? initialValue
    )

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [value, key])

    return [value, setValue]
}

export default useLocalStorage
