import React from 'react'

const RestrictedContent = () => {
    return (
        <div className="flex flex-grow h-full text-center align-items justify-items bg-wbslate">
            <div className="grid max-w-3xl mx-auto place-content-center">
                <h1 className="mb-0 text-4xl font-extrabold tracking-wider text-center text-white uppercase sm:text-5xl md:text-6xl font-openSans">
                    Restricted content
                </h1>
                <p className="font-openSans font-extrabold sm:text-lg tracking-[10%] py-12 uppercase max-w-xl text-center mx-auto text-white">
                    Sorry, Your contract does not allow access to this content.
                    If you believe this is an error, please contact us for
                    support
                </p>
                {/* <button className="uppercase font-openSans font-bold tracking-[20%] text-xl bg-blue text-white py-4.5 px-36 rounded-[3.125rem] mx-auto opacity-70 hover:opacity-100 transition-all mb-12">
                    Request access
                </button> */}
            </div>
        </div>
    )
}

export default RestrictedContent
