import { useEffect, useState, useRef } from 'react'
import useApi from 'hooks/useApi'
import './BrandFranchisePage.scss'
import { Link, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import ExploreOurBrandsFranchises from './components/ExploreOurBrandsFranchises/ExploreOurBrandsFranchises'
import BrandFranchiseOverview from './components/BrandFranchiseOverview/BrandFranchiseOverview'
import BrandFranchiseHero from './components/BrandFranchiseHero/BrandFranchiseHero'
import BrandFranchiseNavLinks from './components/BrandFranchiseNavLinks/BrandFranchiseNavLinks'
import BrandFranchiseGuidelines from './components/BrandFranchiseGuidelines/BrandFranchiseGuidelines'
import BrandFranchiseResources from './components/BrandFranchiseResources/BrandFranchiseResources'
import RestrictedContent from 'components/RestrictedContent/RestrictedContent'
import { useAtom } from 'jotai'
import { showEditButtonAtom } from 'utils/atoms'
import ErrorPage from 'pages/error/ErrorPage'

const BrandFranchisePage = () => {
    const location = useLocation()
    const { property, franchise, brand } = useParams()
    const [showEditButton, setShowEditButton] = useAtom(showEditButtonAtom)
    const {
        responseJSON: content,
        isLoading: contentIsLoading,
        error: contentError,
        restrictedContentError,
    } = useApi(
        `page?slug=${encodeURIComponent(
            location.pathname.replace('/franchise/', '')
        )}&${location.search.replace('?', '')}`,
        'get'
    )
    useEffect(() => {
        const handleKeydown = (event) => {
            if (
                event.shiftKey &&
                (event.ctrlKey || event.metaKey) &&
                event.key === 'E'
            ) {
                setShowEditButton((prev) => !prev)
            }
        }

        window.addEventListener('keydown', handleKeydown, false)

        return () => {
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [showEditButton])

    // Display an full screen empty div with same background along with nav and footer until content loads
    if (content.length < 1 && contentIsLoading)
        return <div className="bg-wbslate min-h-franchise-page"></div>

    // Display an 404 error page when there is no content
    if (contentError) {
        return <ErrorPage className="bg-wbslate" />
    }

    return (
        <>
            {restrictedContentError ? (
                <main className="flex flex-col flex-grow h-full pt-24 bg-wbslate brand-page">
                    <RestrictedContent />
                </main>
            ) : (
                <main className="h-full bg-wbslate brand-page">
                    {/* Hero section */}
                    <BrandFranchiseHero
                        content={content}
                        brand={brand}
                        property={property}
                        franchise={franchise}
                        contentIsLoading={contentIsLoading}
                    />

                    {/* NavLinks section */}
                    <BrandFranchiseNavLinks content={content} />

                    {/* Explore Franchises */}
                    {!property && (
                        <ExploreOurBrandsFranchises content={content} />
                    )}

                    {/* Brand Overview */}
                    <BrandFranchiseOverview
                        content={content}
                        showEditButton={showEditButton}
                    />

                    {/* Brand Guidelines */}
                    <BrandFranchiseGuidelines
                        content={content}
                        showEditButton={showEditButton}
                    />

                    {/* Resources */}
                    <BrandFranchiseResources
                        content={content}
                        showEditButton={showEditButton}
                    />

                    {/* <SurfaceEditButton
            showEditButton={showEditButton}
            setShowEditButton={setShowEditButton}
            ID={content?.ID}
        /> */}
                </main>
            )}
        </>
    )
}

export default BrandFranchisePage
