const ReleaseNote = ({ releaseNote, index }) => {
    return (
        <div className="mb-28">
            {releaseNote.post_title && (
                <h1 className="text-[1.75rem] font-wbSans my-3 text-3xl font-bold text-midblue leading-6.5 capitalize">
                    {releaseNote.post_title}
                </h1>
            )}

            {releaseNote.post_date && (
                <p className="mb-1 text-base leading-5.5 font-bold font-wbSans">
                    {formattedDateString(releaseNote.post_date)}
                </p>
            )}

            <p className="mb-2.5 bg-[#328CF0] font-wbSans px-4 py-1.5 text-white rounded-md leading-5.5 capitalize hidden">
                {releaseNote.post_title}
            </p>

            <div
                className="franchise-wp-content"
                dangerouslySetInnerHTML={{
                    __html:
                        releaseNote && releaseNote.post_content
                            ? releaseNote.post_content
                            : '<h1>Comings Soon</h1>',
                }}
            />
        </div>
    )
}

function formattedDateString(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
}

export default ReleaseNote
