const AgendaEventItem = ({
    event: {
        metadata: {
            display_name,
            event_category,
            startDay,
            startMonth,
            endDay,
            isSingleDay,
            isMultiYear,
            isSameMonth,
            endYear,
            endMonth,
        },
        image,
    },
    MONTHSBYINDEX,
}) => {
    const colorsByEventContentType = {
        WBD: '#ef4444',
        Financials: '#d97706',
        Releases: '#84cc16',
        'Live Events': '#10b981',
        'Related/External': '#06b6d4',
        Public: '#0ea5e9',
        Theme: '#6366f1',
        'Home Entertainment': '#a855f7',
        Podcast: '#ec4899',
        Publishing: '#f43f5e',
        Games: '#a21caf',
        'Made For Video': '#6d28d9',
        Film: '#1d4ed8',
        Series: '#0e7490',
        'Specials (i.e., Holiday Specials)': '#047857',
        'Short Form (Unboxing)': '#ea580c',
    }
    return (
        <div className="pt-2 pl-2">
            <div
                className="py-2 border-l-[7px] flex items-center justify-between gap-x-2"
                style={{
                    borderColor: colorsByEventContentType[event_category],
                }}
            >
                <div className="w-3/4 pl-2">
                    <div className="text-lg font-bold leading-6 tracking-wider uppercase text-blue">
                        {MONTHSBYINDEX[parseInt(startMonth)]} {startDay}{' '}
                        {isMultiYear
                            ? `- ${
                                  MONTHSBYINDEX[parseInt(endMonth)]
                              } ${endDay} ${endYear}`
                            : !isSameMonth
                            ? `- ${MONTHSBYINDEX[parseInt(endMonth)]} ${endDay}`
                            : !isSingleDay
                            ? `- ${endDay}`
                            : ''}
                    </div>
                    <div className="text-[#555555] capitalize font-bold text-lg leading-4.5">
                        {display_name}
                    </div>
                </div>
                {image[0] && (
                    <img
                        src={image[0]}
                        className="object-contain w-20 max-h-[42px]"
                    ></img>
                )}
            </div>
        </div>
    )
}

export default AgendaEventItem
