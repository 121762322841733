import { Outlet } from 'react-router-dom'
import Footer from 'components/Footer/Footer'
import FranchiseTopbar from '../FranchiseTopbar/FranchiseTopbar.js'

export default function FranchiseBase() {
    return (
        <div className="flex flex-col flex-grow bg-white franchise-hub">
            <FranchiseTopbar />
            <Outlet />
            <Footer className="text-white bg-wbslate" />
        </div>
    )
}
