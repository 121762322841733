import AgendaMonthItem from './AgendaMonthItem/AgendaMonthItem'
import { useEffect } from 'react'
const AgendaView = ({
    events,
    currentYear,
    setSortedCategoryTags,
    setEventsCountByYearView,
}) => {
    // Get events that occur starting the current quarter plus 12 months.
    const getQuarterDates = () => {
        const currentMonth = new Date().getMonth()
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3
        const quarterStartDate = new Date(currentYear, quarterStartMonth, 1)
        const quarterEndDate = new Date(currentYear, quarterStartMonth + 2, 31)
        const next12MonthStartDate = new Date(currentYear, quarterStartMonth, 1)
        const next12MonthsEndDate = new Date(
            currentYear,
            quarterStartMonth + 11,
            31
        )

        return {
            quarterStartDate,
            quarterEndDate,
            next12MonthStartDate,
            next12MonthsEndDate,
        }
    }

    const getFilteredEvents = (events) => {
        const quarterDates = getQuarterDates()

        const filteredEvents = events?.filter((event) => {
            const eventStartDate = new Date(
                event.metadata.start_date?.replaceAll('-', '/')
            )

            return (
                eventStartDate >= quarterDates.quarterStartDate &&
                eventStartDate <= quarterDates.next12MonthsEndDate
            )
        })

        return filteredEvents
    }
    const filteredEvents = getFilteredEvents(events)

    useEffect(() => {
        // get only the content types currently displayed by events to show on the topnav
        setSortedCategoryTags(
            Array.from(
                new Set(
                    filteredEvents?.map(
                        (event) => event.metadata.event_category
                    )
                )
            )
                .sort()
                .filter(Boolean)
        )
        setEventsCountByYearView(filteredEvents?.length)
    }, [])
    const generateAgendaViewItems = () => {
        const addAdditionalMetadataToEvents = (events) => {
            events?.forEach((event) => {
                const {
                    metadata: { end_date, start_date },
                } = event

                const [startYear, startMonth, startDay] = start_date.split('-')
                const [endYear, endMonth, endDay] = end_date.split('-')

                const isSingleDay = start_date == end_date
                const isMultiYear = endYear > startYear
                const isSameMonth = startMonth == endMonth

                event.metadata = {
                    ...event.metadata,
                    startMonth,
                    startDay,
                    endYear,
                    endMonth,
                    endDay,
                    isSingleDay,
                    isMultiYear,
                    isSameMonth,
                }
            })
        }

        // Group events by the year and month
        const getEventsByYearAndMonth = (filteredEvents) => {
            const eventsByYearAndMonth = filteredEvents?.reduce(
                (groups, event) => {
                    const eventDate = new Date(
                        event.metadata.start_date?.replaceAll('-', '/')
                    )
                    const year = eventDate.getFullYear()
                    const month = eventDate.getMonth()

                    const key = `${year}-${month}`
                    if (!groups[key]) {
                        groups[key] = []
                    }
                    groups[key].push(event)

                    return groups
                },
                {}
            )

            return eventsByYearAndMonth
        }

        addAdditionalMetadataToEvents(filteredEvents)
        const eventsByYearAndMonth = getEventsByYearAndMonth(filteredEvents)

        const headersSortedByDate = Object.keys(eventsByYearAndMonth).sort(
            (a, b) => {
                const [yearA, monthA] = a.split('-')
                const [yearB, monthB] = b.split('-')
                return new Date(yearA, monthA) - new Date(yearB, monthB)
            }
        )

        return {
            agendaViewEvents: eventsByYearAndMonth,
            agendaViewHeaders: headersSortedByDate,
        }
    }

    const { agendaViewEvents, agendaViewHeaders } = generateAgendaViewItems()

    return (
        <div className="w-full pb-10 overflow-x-auto bg-white border-collapse min-h-[400px] rounded-br-xl rounded-bl-xl">
            {agendaViewEvents.length === 0 || filteredEvents?.length === 0 ? (
                <h4 className="ml-10">No events available...</h4>
            ) : (
                <div className="grid px-8 mx-auto md:px-16 sm:grid-cols-2 lg:grid-cols-3 pb-14 max-w-8xl gap-y-8 sm:gap-y-24 gap-x-10">
                    {agendaViewHeaders?.map((header) => (
                        <AgendaMonthItem
                            key={header}
                            header={header}
                            eventsByMonth={agendaViewEvents[header]}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default AgendaView
