import React from 'react'
import './Loading.scss'

const Loading = ({ type = 13, width = 4, height = 4 }) => {
    return (
        <div
            className={`loader-${type} w-${width} h-${height} bg-transparent border-none`}
        ></div>
    )
}

export default Loading
