import { ReactComponent as LogoIcon } from '__shared/images/icons/logo.svg'
import './Nav.scss'
import { globalNavLinks } from 'utils/constants'

export default function Nav() {
    return (
        <nav className="grid items-center ">
            <div className="logo">
                <a href="/">
                    <LogoIcon />
                </a>
            </div>
            <ul className="flex flex-col flex-wrap items-end justify-end sm:items-center sm:flex-row">
                {globalNavLinks.map(({ name, ...rest }, index) => {
                    return (
                        <li className="sm:mx-2.5 inline-block" key={index}>
                            <a {...rest}>{name}</a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}
