import { Outlet, Navigate, useLocation } from 'react-router-dom'
// import { useAtomValue } from 'jotai'
// import { authAtom } from '../../utils/atoms'
import { useCookies } from 'react-cookie'

export const ProtectedRoute = () => {
    const [cookies] = useCookies(['authToken'])
    const authToken = cookies.authToken
    const location = useLocation()
    return !authToken ? (
        <Navigate
            to="/login"
            replace
            state={{
                from: location,
            }}
        />
    ) : (
        <Outlet />
    )
}
