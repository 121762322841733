import Select from 'react-select'

const reactSelectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: 'transparent',
        backgroundColor: '#EEEEEE',
        minHeight: '28px',
        height: '28px',
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0074e8',
        fontWeight: 700,
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        margin: 0,
        padding: 0,
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '28px',
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0px',
        height: '28px',
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        width: 0,
        color: '#0074e8',
        margin: 0,
        padding: 0,
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0074e8',
        ':hover': {
            color: '#0074e8',
        },
        paddingTop: 0,
        paddingBottom: 0,
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        marginTop: '1px',
    }),
    menuList: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
    }),
}

const YearSelectInputs = ({
    years,
    currentEndFilterIndex,
    currentStartFilterIndex,
    setCurrentEndFilterIndex,
    setCurrentStartFilterIndex,
    setFilter,
}) => {
    function handleSelectYearChange(year, position) {
        let selectedIndex = years.findIndex((y) => y == year.value)
        position === 'start'
            ? setCurrentStartFilterIndex(selectedIndex)
            : setCurrentEndFilterIndex(selectedIndex)
        setFilter((prev) => ({
            ...prev,
            type: 'year',
            [position]: years[selectedIndex],
        }))
    }
    function getStartFilterYears(years) {
        return years
            .filter((year) => year <= years[currentEndFilterIndex])
            .map((year) => ({
                value: year,
                label: year,
            }))
    }
    function getEndFilterYears(years) {
        return years
            .filter((year) => year >= years[currentStartFilterIndex])
            .map((year) => ({ value: year, label: year }))
    }
    return (
        <div className="flex items-center justify-end p-1 mb-12 gap-x-2 ">
            <p className="text-[18px] font-bold uppercase font-wbSans text-wbgray">
                Select range
            </p>
            <Select
                styles={reactSelectStyles}
                options={getStartFilterYears(years)}
                defaultValue={{
                    label: years[0],
                    value: years[0],
                }}
                onChange={(year) => handleSelectYearChange(year, 'start')}
            />
            -
            <Select
                styles={reactSelectStyles}
                options={getEndFilterYears(years)}
                defaultValue={{
                    label: years[currentEndFilterIndex],
                    value: years[currentEndFilterIndex],
                }}
                onChange={(year) => handleSelectYearChange(year, 'end')}
            />
        </div>
    )
}

export default YearSelectInputs
