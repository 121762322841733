import React from 'react'
import { ReactComponent as PencilIcon } from '__shared/images/icons/pencil.svg'
import './SectionEditButton.scss'
import { twMerge } from 'tailwind-merge'
import { useAtomValue } from 'jotai'
import { showEditButtonAtom } from 'utils/atoms'
const SectionEditButton = ({ hash, id, className, ...props }) => {
    const showEditButton = useAtomValue(showEditButtonAtom)
    const c = twMerge(
        'flex items-center justify-between px-2 py-1 text-xl font-bold text-white rounded gap-x-1 bg-blue hover:bg-midbluehover focus:ring-2 focus:ring-midblue focus:ring-offset-2 absolute top-0 right-0 z-50 mt-4 mr-5',
        className
    )
    return (
        <button
            {...props}
            onClick={() =>
                window.open(
                    `https://${
                        window.location.host !==
                        'seeker.franchise.warnerbros.com'
                            ? 'stage.'
                            : ''
                    }cms.franchise.warnerbros.com/wp-admin/post.php?post=${id}&action=edit${hash}`,
                    '_blank',
                    'rel=noopener noreferrer'
                )
            }
            className={`${showEditButton ? c : 'hidden'}`}
        >
            <PencilIcon className="w-4 h-4 pencil-edit fill-inherit" />
            <span>Edit</span>
        </button>
    )
}

export default SectionEditButton
