import { useEffect, useState } from 'react'
import { flat } from 'utils/utils'
import { DebounceInput } from 'react-debounce-input'
import { useAtomValue } from 'jotai'
import { navAtom } from 'utils/atoms'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import './Search.scss'

export default function Search({ className }) {
    const [term, setTerm] = useState('')
    const [matches, setMatches] = useState([])
    const nav = useAtomValue(navAtom)

    useEffect(() => {
        if (term.length < 3) {
            setMatches([])
            return
        }

        const searchableNav = flat(nav)
        const matched = searchableNav.filter((i) => {
            return i.title.toLowerCase().indexOf(term) > -1
        })
        console.log(matched)
        setMatches(matched)
    }, [term, nav])

    return (
        <div className={`search-container ${className}`}>
            <div className="flex items-center gap-x-2 ">
                <DebounceInput
                    minLength={3}
                    onKeyDown={(event) => setTerm(event.target.value)}
                    debounceTimeout={500}
                    className="py-2 pl-4 text-sm bg-[#d9d9d9] rounded-md"
                    id="search-input"
                />
                <label title="Search" className="text-3xl" for="search-input">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </label>
            </div>
            <div className="matches">
                {matches.map((match) => {
                    return (
                        <div>
                            <Link
                                to={match.slug}
                                onClick={() => setTerm('')}
                                className="text-xs"
                            >
                                {match.title}
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
